import {
    Firestore,
    DocSnapTyped,
    DocumentReference,
    Query,
    QuerySnapshot,
    UpdateData,
    QuerySnapTyped,
    newFieldValueIncrement,
    Transaction,
    Timestamp,
} from './FirebaseTypes'
import {
    BibleRefRange,
    LocationData,
    Sermon,
    SermonData,
    SermonLocationData,
    TagData,
    TextDocumentData,
    UserData,
    UserOneDriveData,
    UserRole
} from './Types'

// This must be initialized via initializeDatabase()
let db: Firestore

export interface UserQueryParams {
    companyId?: string
    isTemp?: boolean
    orderByCreatedTimestampDescending?: boolean
}

export interface UpdateUserParams {
    newFirstName?: string
    newLastName?: string
    newName?: string
    newEmail?: string
    newRole?: UserRole
    newOneDrive?: UpdateUserOneDriveParams
}

export interface UpdateUserOneDriveParams {
    scope?: string
    redirectUri?: string
    accessToken?: string
    refreshToken?: string
    expiresAtTimestamp?: Timestamp
    sermonsRootFolderPath?: string
}

export interface SermonQueryParams {
    userId?: string
    orderBySermonRefThenCreatedTimestampDescending?: boolean
    limit?: number
    startAfter?: Sermon
}

export interface UpdateSermonParams {
    newSermonRef?: number
    newTitle?: string
    newBibleRefs?: BibleRefRange[]
    newTagIds?: string[]
    newLocations?: SermonLocationData[]
    newEarliestLocationDateText?: string        // YYYY-MM-DD - earliest by date, not array order
    newLatestLocationDateText?: string          // YYYY-MM-DD - latest by date, not array order
    newNotes?: TextDocumentData
}

export interface UpdateTagParams {
    newName?: string
    sermonCountIncrement?: number       // can be positive or negative
}

export interface TagQueryParams {
    userId: string
}

export interface UpdateLocationParams {
    newName?: string
    sermonCountIncrement?: number       // can be positive or negative
}

export interface LocationQueryParams {
    userId: string
}

//-------------------------------------------------------------------------------------------------
// General Functions
//-------------------------------------------------------------------------------------------------

/**
 * Called to initialize any global variables in this file
 */
export function initializeDatabase(_db: Firestore) {
    db = _db
}

export function runTransaction<T>(updateFunction: (transaction: Transaction) => Promise<T>): Promise<T> {
    return db.runTransaction(updateFunction)
}

//-------------------------------------------------------------------------------------------------
// User Functions
//-------------------------------------------------------------------------------------------------

export function createUserId(): string {
    return db.collection('users').doc().id
}

export function getUserDocRef(id: string): DocumentReference {
    return db.doc(`users/${id}`);
}

export async function getUserData(id: string): Promise<UserData|undefined> {
    const doc = await getUserDocRef(id).get()
    return doc.data() as UserData
}

export async function setUserData(id: string, data: UserData): Promise<void> {
    await getUserDocRef(id).set(data)
}

export async function updateUserData(id: string, params: UpdateUserParams): Promise<void> {
    const updateData: UpdateData = {}

    if (params.newFirstName) {
        updateData.firstName = params.newFirstName
        updateData.firstNameLower = params.newFirstName.toLowerCase()
    }
    if (params.newLastName) {
        updateData.lastName = params.newLastName
        updateData.lastNameLower = params.newLastName.toLowerCase()
    }
    if (params.newName) {
        updateData.name = params.newName
        updateData.nameLower = params.newName.toLowerCase()
    }
    if (params.newEmail)
        updateData.email = params.newEmail
    if (params.newRole)
        updateData.role = params.newRole
    if (params.newOneDrive) {
        if (params.newOneDrive.scope)
            updateData['oneDrive.scope'] = params.newOneDrive.scope
        if (params.newOneDrive.redirectUri)
            updateData['oneDrive.redirectUri'] = params.newOneDrive.redirectUri
        if (params.newOneDrive.accessToken)
            updateData['oneDrive.accessToken'] = params.newOneDrive.accessToken
        if (params.newOneDrive.refreshToken)
            updateData['oneDrive.refreshToken'] = params.newOneDrive.refreshToken
        if (params.newOneDrive.expiresAtTimestamp)
            updateData['oneDrive.expiresAtTimestamp'] = params.newOneDrive.expiresAtTimestamp
        if (params.newOneDrive.sermonsRootFolderPath)
            updateData['oneDrive.sermonsRootFolderPath'] = params.newOneDrive.sermonsRootFolderPath
    }

    await getUserDocRef(id).update(updateData)
}

export async function deleteUser(id: string): Promise<void> {
    await getUserDocRef(id).delete()
}

export function streamUserData(id: string, onNext: (snapshot: DocSnapTyped<UserData>) => void): VoidFunction {
    return streamDoc<UserData>(getUserDocRef(id), onNext)
}

export function streamUsers(params: UserQueryParams,
                            onNext: (snapshot: QuerySnapshot) => void,
                            onError?: (error: Error) => void): VoidFunction {
    const query = createUserQuery(params)
    return query.onSnapshot(onNext, onError)
}

function createUserQuery(params?: UserQueryParams): Query {
    let query: Query = db.collection('users')

    if (params) {
        if (params.companyId)
            query = query.where('companyId', '==', params.companyId)
        if (params.isTemp !== undefined)
            query = query.where('isTemp', '==', params.isTemp)
        if (params.orderByCreatedTimestampDescending)
            query = query.orderBy('createdTimestamp', 'desc')
    }

    return query
}

//-------------------------------------------------------------------------------------------------
// Sermon Functions
//-------------------------------------------------------------------------------------------------

export function createSermonId(): string {
    return db.collection('sermons').doc().id
}

export function getSermonDocRef(id: string): DocumentReference {
    return db.doc(`sermons/${id}`);
}

export async function getSermonData(id: string): Promise<SermonData|undefined> {
    const doc = await getSermonDocRef(id).get()
    return doc.data() as SermonData
}

export function createSermonUpdateData(params: UpdateSermonParams): UpdateData {
    const updateData: UpdateData = {}
    if (params.newSermonRef)
        updateData.sermonRef = params.newSermonRef
    if (params.newTitle)
        updateData.title = params.newTitle
    if (params.newBibleRefs)
        updateData.bibleRefs = params.newBibleRefs
    if (params.newTagIds)
        updateData.tagIds = params.newTagIds
    if (params.newLocations)
        updateData.locations = params.newLocations
    if (params.newEarliestLocationDateText)
        updateData.earliestLocationDateText = params.newEarliestLocationDateText
    if (params.newLatestLocationDateText)
        updateData.latestLocationDateText = params.newLatestLocationDateText
    if (params.newNotes)
        updateData.notes = params.newNotes
    return updateData
}

export async function querySermons(params?: SermonQueryParams): Promise<QuerySnapTyped<SermonData>> {
    const query = createSermonQuery(params)
    const snapshot = await query.get()
    return QuerySnapTyped.fromSnapshot(snapshot, data => data as SermonData)
}

export function streamSermons(params: SermonQueryParams,
                              onNext: (snapshot: QuerySnapshot) => void,
                              onError?: (error: Error) => void): VoidFunction {
    const query = createSermonQuery(params)
    return query.onSnapshot(onNext, onError)
}

function createSermonQuery(params?: SermonQueryParams): Query {
    let query: Query = db.collection('sermons')

    if (params) {
        if (params.userId)
            query = query.where('userId', '==', params.userId)
        if (params.orderBySermonRefThenCreatedTimestampDescending)
            query = query.orderBy('sermonRef', 'desc').orderBy('createdTimestamp', 'desc')
        if (params.limit)
            query = query.limit(params.limit)
        if (params.startAfter)
            query = query.startAfter(params.startAfter.data.sermonRef, params.startAfter.data.createdTimestamp)
    }

    return query
}

//-------------------------------------------------------------------------------------------------
// Tag Functions
//-------------------------------------------------------------------------------------------------

export async function addTag(data: TagData): Promise<string> {
    const doc = await db.collection('tags').add(data)
    return doc.id
}

export function getTagDocRef(id: string): DocumentReference {
    return db.doc(`tags/${id}`);
}

export async function getTagData(id: string): Promise<TagData|undefined> {
    const doc = await getTagDocRef(id).get()
    return doc.data() as TagData
}

export async function updateTagData(id: string, params: UpdateTagParams): Promise<void> {
    const updateData = createTagUpdateData(params)
    await getTagDocRef(id).update(updateData)
}

export function createTagUpdateData(params: UpdateTagParams): UpdateData {
    const updateData: UpdateData = {}
    if (params.newName) {
        updateData.name = params.newName
        updateData.nameLower = params.newName.toLowerCase()
    }
    if (params.sermonCountIncrement)
        updateData.sermonCount = newFieldValueIncrement(params.sermonCountIncrement)
    return updateData
}

export function streamTags(params: TagQueryParams,
                           onNext: (snapshot: QuerySnapshot) => void,
                           onError?: (error: Error) => void): VoidFunction {
    const query = createTagQuery(params)
    return query.onSnapshot(onNext, onError)
}

function createTagQuery(params?: TagQueryParams): Query {
    let query: Query = db.collection('tags')

    if (params) {
        if (params.userId)
            query = query.where('userId', '==', params.userId)
    }

    return query
}

//-------------------------------------------------------------------------------------------------
// Location Functions
//-------------------------------------------------------------------------------------------------

export async function addLocation(data: LocationData): Promise<string> {
    const doc = await db.collection('locations').add(data)
    return doc.id
}

export function getLocationDocRef(id: string): DocumentReference {
    return db.doc(`locations/${id}`);
}

export async function getLocationData(id: string): Promise<LocationData|undefined> {
    const doc = await getLocationDocRef(id).get()
    return doc.data() as LocationData
}

export async function updateLocationData(id: string, params: UpdateLocationParams): Promise<void> {
    const updateData = createLocationUpdateData(params)
    await getLocationDocRef(id).update(updateData)
}

export function createLocationUpdateData(params: UpdateLocationParams): UpdateData {
    const updateData: UpdateData = {}
    if (params.newName) {
        updateData.name = params.newName
        updateData.nameLower = params.newName.toLowerCase()
    }
    if (params.sermonCountIncrement)
        updateData.sermonCount = newFieldValueIncrement(params.sermonCountIncrement)
    return updateData
}

export function streamLocations(params: LocationQueryParams,
                                onNext: (snapshot: QuerySnapshot) => void,
                                onError?: (error: Error) => void): VoidFunction {
    const query = createLocationQuery(params)
    return query.onSnapshot(onNext, onError)
}

function createLocationQuery(params?: LocationQueryParams): Query {
    let query: Query = db.collection('locations')

    if (params) {
        if (params.userId)
            query = query.where('userId', '==', params.userId)
    }

    return query
}

//-------------------------------------------------------------------------------------------------
// Helper Functions
//-------------------------------------------------------------------------------------------------

function streamDoc<T>(docRef: DocumentReference, onNext: (snapshot: DocSnapTyped<T>) => void): VoidFunction {
    return docRef.onSnapshot(doc =>
        onNext(new DocSnapTyped(doc, data => data as T))
    )
}