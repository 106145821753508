














import { Component, Vue } from 'vue-property-decorator'
import appManager from '@/services/AppManager'
import { AuthState, AuthStateData } from '@/services/UserManager'
import EntranceView from '@/views/EntranceView.vue'
import Portal from '@/views/Portal.vue'
import AlertMessageCenter from '@/components/AlertMessageCenter.vue'

@Component({
    components: {
        EntranceView,
        Portal,
        AlertMessageCenter
    }
})
export default class AppPortal extends Vue {
    state: AuthStateData|null = null

    // noinspection JSUnusedGlobalSymbols
    created() {
        appManager.userManager.stateStream.subscribe(state => this.onAuthStateChanged(state))
    }

    isInitializing(): boolean {
        return !this.state?.state
    }

    isLoggedIn(): boolean {
        return !!this.state?.user && (this.state.state === AuthState.LOGGED_IN || this.state.state === AuthState.LOGGING_OUT)
    }

    private onAuthStateChanged(state: AuthStateData) {
        console.log(`Auth State: ${state?.state}`)
        this.state = state
    }
}
