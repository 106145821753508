



































































import Vue from 'vue'
import Component from 'vue-class-component'
import Spinner from '@/components/Spinner.vue'
import { Tag } from '@/core/Types'
import appManager from '@/services/AppManager'
import { Subscription } from 'rxjs'
import { UserMetadataState } from '@/services/UserMetadataManager'
import CreateTagDialog from '@/components/tags/CreateTagDialog.vue'
import EditTagDialog from '@/components/tags/EditTagDialog.vue'

@Component({
    components: {
        Spinner,
        CreateTagDialog,
        EditTagDialog
    }
})
export default class TagsView extends Vue {
    // tags
    userMetadataSnapshotCounter: number = 0 // simple way to force a refresh when new items arrive
    tags: Tag[] = []
    userMetadataSubscription: Subscription|null = null

    // create tag dialog
    isCreateTagDialogVisible: boolean = false
    createTagDialogCounter: number = 0 // simple way to reset the dialog each time it is opened

    // edit tag dialog
    isEditTagDialogVisible: boolean = false
    editTag: Tag|null = null
    editTagDialogCounter: number = 0 // simple way to reset the dialog before opening each time

    created() {
        this.userMetadataSubscription = appManager.userMetadataManager.stateStream.subscribe(
            state => this.onUserMetadataChanged(state)
        )
    }

    destroyed() {
        if (this.userMetadataSubscription) {
            this.userMetadataSubscription.unsubscribe()
            this.userMetadataSubscription = null
        }
    }

    onUserMetadataChanged(state: UserMetadataState) {
        this.tags = state.tags
        this.userMetadataSnapshotCounter++
    }

    onAddTagPressed() {
        this.createTagDialogCounter += 1
        this.isCreateTagDialogVisible = true
    }

    onCloseCreateTagDialogRequested() {
        this.isCreateTagDialogVisible = false
    }

    onEditTagPressed(tag: Tag) {
        this.editTagDialogCounter += 1
        this.editTag = tag
        this.isEditTagDialogVisible = true
    }

    onCloseEditTagDialogRequested() {
        this.isEditTagDialogVisible = false
    }
}
