import firebase from 'firebase'
import * as db from '@/core/Database'
import { BaseStateManager } from '@/services/BaseStateManager'
import { User, UserData } from '@/core/Types'
import { Log } from '@/services/Log'
import { DocSnapTyped } from '@/core/FirebaseTypes'

export enum AuthState {
    LOGGED_OUT = 'LOGGED_OUT',
    LOGGING_IN = 'LOGGING_IN',
    LOGGED_IN = 'LOGGED_IN',
    LOGGING_OUT = 'LOGGING_OUT'
}

/**
 * State data
 * principal - the actual logged in user
 * user - either the principal or the virtual user the principal is logged in as
 */
export class AuthStateData {
    static readonly loggedOut = new AuthStateData(AuthState.LOGGED_OUT, undefined, undefined)
    static readonly loggingIn = new AuthStateData(AuthState.LOGGING_IN, undefined, undefined)

    constructor(public readonly state: AuthState|undefined,
                public readonly user: User|undefined,
                public readonly principal: User|undefined) {
    }
}

const log = new Log('UserManager')

export class UserManager extends BaseStateManager<AuthStateData>{
    private principalId: string|undefined
    private principalUnsubscriber: VoidFunction|undefined

    private virtualUserId: string|null = null
    private virtualUserUnsubscriber: VoidFunction|undefined

    constructor() {
        super(new AuthStateData(undefined, undefined, undefined))

        firebase.auth().onAuthStateChanged(user => this.onFirebaseAuthStateChanged(user))

        // call current user to load previous user when the page loads
        firebase.auth().currentUser
    }

    async signIn(email: string, password: string): Promise<void> {
        this._setAndPublishState(AuthStateData.loggingIn)

        try {
            await firebase.auth().signInWithEmailAndPassword(email, password)
        } catch (error) {
            this._setAndPublishState(AuthStateData.loggedOut)
            throw error
        }
    }

    signOut(): Promise<void> {
        this._setAndPublishState(new AuthStateData(AuthState.LOGGING_OUT, this.state.user, this.state.principal))

        return firebase.auth().signOut()
    }

    setVirtualUserId(userId: string|null) {
        this.virtualUserId = userId

        if (userId) {
            this.startVirtualUserSubscription()
        } else {
            this.cancelVirtualUserSubscription()
            this._setAndPublishState(new AuthStateData(AuthState.LOGGED_IN, this.state.principal, this.state.principal))
        }
    }

    async createUserToken(): Promise<string|undefined> {
        return firebase.auth().currentUser?.getIdToken(true)
    }

    async updatePassword(currentPassword: string, newPassword: string): Promise<void> {
        const fbUser = firebase.auth().currentUser
        if (!fbUser?.email)
            return

        // if too much time has elapsed since user login, Firebase will request the user to reauthenticate.
        // go ahead and preempt that process by manually reauthenticating.
        const credential = firebase.auth.EmailAuthProvider.credential(fbUser.email, currentPassword)
        await fbUser?.reauthenticateWithCredential(credential)

        // change the password
        await firebase.auth().currentUser?.updatePassword(newPassword)
    }

    private onFirebaseAuthStateChanged(user: firebase.User|null) {
        if (!user) {
            log.info('Firebase User Logged Out')
            this.principalId = undefined
            this.cancelPrincipalSubscription()
            this._setAndPublishState(AuthStateData.loggedOut)
        } else if (user.uid !== this.principalId) {
            log.info(`Firebase User Logged In: ${user.displayName} ${user.uid}`)
            this.principalId = user.uid
            this.startPrincipalSubscription()
        }
    }

    private startPrincipalSubscription() {
        this.cancelPrincipalSubscription()

        if (this.principalId) {
            const userId = this.principalId
            this.principalUnsubscriber = db.streamUserData(userId, snapshot =>
                this.onPrincipalUserDataReceived(userId, snapshot)
            )
        }
    }

    private startVirtualUserSubscription() {
        this.cancelVirtualUserSubscription()

        if (this.virtualUserId) {
            const userId = this.virtualUserId
            this.virtualUserUnsubscriber = db.streamUserData(userId, snapshot =>
                this.onVirtualUserDataReceived(userId, snapshot)
            )
        }
    }

    private cancelPrincipalSubscription() {
        if (this.principalUnsubscriber) {
            this.principalUnsubscriber()
            this.principalUnsubscriber = undefined
        }
    }

    private cancelVirtualUserSubscription() {
        if (this.virtualUserUnsubscriber) {
            this.virtualUserUnsubscriber()
            this.virtualUserUnsubscriber = undefined
        }
    }

    private onPrincipalUserDataReceived(userId: string, snapshot: DocSnapTyped<UserData>) {
        if (userId === this.principalId) {
            const userData = snapshot.data
            if (userData?.isActive) {
                const principal: User = { id: userId, data: userData }

                // assign either the existing virtual user or principal as the user
                const existingUser = this.state.user
                const user = (!existingUser || !this.virtualUserId || existingUser.id === userId) ? principal : existingUser

                this._setAndPublishState(new AuthStateData(AuthState.LOGGED_IN, user, principal))
            } else {
                this.signOut().then()
            }
        }
    }

    private onVirtualUserDataReceived(userId: string, snapshot: DocSnapTyped<UserData>) {
        if (userId === this.virtualUserId) {
            const userData = snapshot.data
            if (userData) {
                const user: User = { id: userId, data: userData }
                this._setAndPublishState(new AuthStateData(AuthState.LOGGED_IN, user, this.state.principal))
            } else {
                // if this user doesn't exist, clear user virtualization
                this.setVirtualUserId(null)
            }
        }
    }
}