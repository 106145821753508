import { BaseStateManager } from '@/services/BaseStateManager'

export enum AlertMessageType {
    NONE,
    INFO,
    SUCCESS,
    ERROR
}

export class AlertMessage {
    constructor(readonly type: AlertMessageType,
                readonly text: string) {
    }

    static info(text: string): AlertMessage {
        return new AlertMessage(AlertMessageType.INFO, text)
    }

    static success(text: string): AlertMessage {
        return new AlertMessage(AlertMessageType.SUCCESS, text)
    }

    static error(text: string): AlertMessage {
        return new AlertMessage(AlertMessageType.ERROR, text)
    }
}

export class AlertManager extends BaseStateManager<AlertMessage> {
    constructor() {
        super(new AlertMessage(AlertMessageType.NONE, ''))
    }

    publishInfo(text: string) {
        this._setAndPublishState(AlertMessage.info(text))
    }

    publishSuccess(text: string) {
        this._setAndPublishState(AlertMessage.success(text))
    }

    publishError(text: string) {
        this._setAndPublishState(AlertMessage.error(text))
    }
}