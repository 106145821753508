





















































































import Component from 'vue-class-component'
import appManager from '@/services/AppManager'
import { RoutePaths } from '@/router'
import { BaseVueComponent } from '@/components/BaseVueComponent'
import { Subscription as RxSubscription } from 'rxjs'
import { UserRole } from '@/core/Types'
import { AuthStateData } from '@/services/UserManager'

interface DrawerItem {
    icon: string
    text: string
    link: string
}

@Component
export default class Portal extends BaseVueComponent {
    // user state
    userState: AuthStateData|null = null
    userStateCounter: number = 0 // simple way to force a refresh when the state changes
    userStateSubscription: RxSubscription|undefined

    virtualUserId: string|null = null

    drawer: boolean|null = null
    drawerItemsUser: DrawerItem[] = [
        {icon: 'mdi-home-outline', text: 'Home', link: RoutePaths.PORTAL_HOME},
        {icon: 'mdi-note-multiple-outline', text: 'Sermons', link: RoutePaths.SERMONS},
        {icon: 'mdi-tag-outline', text: 'Tags', link: RoutePaths.TAGS},
        {icon: 'mdi-map-marker-outline', text: 'Locations', link: RoutePaths.LOCATIONS},
        {icon: 'mdi-account-circle-outline', text: 'My Profile', link: RoutePaths.MY_PROFILE},
        {icon: 'mdi-microsoft-onedrive', text: 'OneDrive', link: RoutePaths.ONEDRIVE}
    ]

    // noinspection JSUnusedGlobalSymbols
    created() {
        this.userStateSubscription = appManager.userManager.stateStream.subscribe(
            state => this.onUserStateChanged(state)
        )
    }

    // noinspection JSUnusedGlobalSymbols
    destroyed() {
        if (this.userStateSubscription)
            this.userStateSubscription.unsubscribe()
    }

    getDrawerItems(): DrawerItem[] {
        const items: DrawerItem[] = []

        items.push(...this.drawerItemsUser)

        return items
    }

    isPrincipalAdmin(): boolean {
        return this.userState?.principal?.data?.role === UserRole.ADMIN
    }

    isVirtualizingUser(): boolean {
        const principalId = this.userState?.principal?.id
        const userId = this.userState?.user?.id

        return !!principalId && !!userId && principalId !== userId
    }

    getAppVersion(): string {
        return appManager.appVersion
    }

    getUserName(): string {
        return this.userState?.user?.data?.name || ''
    }

    async onLogOutPressed() {
        await appManager.userManager.signOut()
        await this.pushRouteIfNew(RoutePaths.PORTAL_HOME)
    }

    private onUserStateChanged(state: AuthStateData) {
        this.userState = state
        this.userStateCounter += 1
    }

    private onChangeVirtualUserIdPressed() {
        appManager.userManager.setVirtualUserId(this.virtualUserId)
        this.pushRouteIfNew(RoutePaths.PORTAL_HOME)
    }
}
