










































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import FormTextField from '@/components/FormTextField.vue'
import { validatePassword } from '@/core/Utils'
import appManager from '@/services/AppManager'
import { FirebaseAuthErrorCodes } from '@/core/FirebaseTypes'

@Component({
    components: {
        FormTextField
    }
})
export default class ChangeCurrentUserPasswordDialog extends Vue {
    @Prop()
    value: boolean|undefined // true to show the dialog
    @Prop()
    onCloseRequested: (() => void)|undefined
    currentPassword: string|null = null
    currentPasswordErrorMessage: string|null = null
    newPassword: string|null = null
    newPasswordErrorMessage: string|null = null
    confirmPassword: string|null = null
    confirmPasswordErrorMessage: string|null = null
    formErrorMessage: string|null = null
    isChanging: boolean = false

    private onCancelPressed() {
        if (this.onCloseRequested)
            this.onCloseRequested()
    }

    private async onChangePasswordPressed(): Promise<void> {
        this.validatePage()
        if (this.formErrorMessage || !this.currentPassword || !this.newPassword)
            return

        this.isChanging = true
        try {
            await appManager.userManager.updatePassword(this.currentPassword, this.newPassword)

            // password was changed, so close this dialog
            if (this.onCloseRequested)
                this.onCloseRequested()

            appManager.alertManager.publishSuccess('Your password was updated.')
        } catch (error) {
            console.error(`Error Changing Password: ${JSON.stringify(error)}`)
            if (error.code === FirebaseAuthErrorCodes.WRONG_PASSWORD)
                this.formErrorMessage = 'Invalid current password'
            else
                this.formErrorMessage = 'There was an error updating your password. Please try again later.'
        }
        this.isChanging = false
    }

    validatePage() {
        this.currentPasswordErrorMessage = !this.currentPassword ? 'Must specify current password' : null
        this.newPasswordErrorMessage = validatePassword(this.newPassword)
        this.confirmPasswordErrorMessage = (this.newPassword !== this.confirmPassword) ? 'Passwords do not match' : null

        const hasError = !!this.currentPasswordErrorMessage ||
            !!this.newPasswordErrorMessage ||
            !!this.confirmPasswordErrorMessage
        this.formErrorMessage = hasError ? 'Please correct the error above' : null
    }
}
