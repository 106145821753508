



















import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { BaseVueComponent } from '@/components/BaseVueComponent'

@Component
export default class FormTextField extends BaseVueComponent {
    @Prop({ type: String })
    value: string|undefined
    @Prop({ type: String })
    label: string|undefined
    @Prop({ type: Boolean, default: false })
    embedLabel: boolean|undefined
    @Prop({ type: String, default: 'text' })
    type: string|undefined
    @Prop({ type: Boolean, default: false })
    disableAutoComplete: boolean|undefined
    @Prop({ type: Boolean, default: false })
    hideDetails: boolean|undefined
    @Prop({ type: String })
    errorMessage: string|undefined
    @Prop()
    onEnterPressed: (() => void)|undefined
    showPassword: boolean = false // only used if type = 'password'

    get isPassword(): boolean {
        return this.type === 'password'
    }

    getType(): string|undefined {
        return !this.isPassword ? this.type : (this.showPassword ? 'type' : 'password')
    }

    getAppendIcon(): string|undefined {
        return !this.isPassword ? undefined : (this.showPassword ? 'mdi-eye-off' : 'mdi-eye')
    }

    onValueChanged(text: string|undefined) {
        this.emitValueChanged(text)
    }

    onKeyUpEnter() {
        if (this.onEnterPressed)
            this.onEnterPressed()
    }
}
