import * as db from '@/core/Database'
import { BaseStateManager } from '@/services/BaseStateManager'
import { AuthStateData, UserManager } from '@/services/UserManager'
import { QuerySnapshot } from '@/core/FirebaseTypes'
import { Location, LocationData, Tag, TagData } from '@/core/Types'

export interface UserMetadataState {
    auth: AuthStateData|undefined
    tags: Tag[]                         // sorted by name
    locations: Location[]               // sorted by name
}

/**
 * Monitors the active user and streams the company (if any) associated with the user.
 */
export class UserMetadataManager extends BaseStateManager<UserMetadataState> {
    tagsStreamUnsubscriber: VoidFunction|null = null
    locationsStreamUnsubscriber: VoidFunction|null = null

    constructor(private readonly userManager: UserManager) {
        super({auth: undefined, tags: [], locations: []});

        userManager.stateStream.subscribe(state => this.onUserStateChanged(state))
    }

    private onUserStateChanged(authState: AuthStateData) {
        const newUserId = authState?.user?.id
        const existingUserId = this.state?.auth?.user?.id

        this._setAndPublishState({
            auth: authState,
            tags: (newUserId === existingUserId) ? this.state.tags : [],
            locations: (newUserId === existingUserId) ? this.state.locations : []
        })

        // if the user to be streamed changed, reset the streams
        if (newUserId !== existingUserId)
            this.resetLocationsStream(newUserId)
    }

    private resetLocationsStream(newUserId: string|undefined) {
        // cancel old streams
        if (this.tagsStreamUnsubscriber) {
            this.tagsStreamUnsubscriber()
            this.tagsStreamUnsubscriber = null
        }
        if (this.locationsStreamUnsubscriber) {
            this.locationsStreamUnsubscriber()
            this.locationsStreamUnsubscriber = null
        }

        // start new stream if the user is active
        if (newUserId) {
            this.tagsStreamUnsubscriber = db.streamTags(
                { userId: newUserId },
                snapshot => this.onTagsReceived(newUserId, snapshot)
            )
            this.locationsStreamUnsubscriber = db.streamLocations(
                { userId: newUserId },
                snapshot => this.onLocationsReceived(newUserId, snapshot)
            )
        }
    }

    private onTagsReceived(userId: string, snapshot: QuerySnapshot) {
        // make sure this is still the active user
        if (userId === this.state?.auth?.user?.id) {
            const tags: Tag[] = snapshot.docs
                .filter(doc => doc.exists)
                .map(doc => {
                    return {
                        id: doc.id,
                        data: doc.data() as TagData
                    }
                })
            tags.sort((t1, t2) => t1.data.nameLower.localeCompare(t2.data.nameLower))

            this._setAndPublishState({
                auth: this.state.auth,
                tags: tags,
                locations: this.state.locations
            })
        }
    }

    private onLocationsReceived(userId: string, snapshot: QuerySnapshot) {
        // make sure this is still the active user
        if (userId === this.state?.auth?.user?.id) {
            const locations: Location[] = snapshot.docs
                .filter(doc => doc.exists)
                .map(doc => {
                    return {
                        id: doc.id,
                        data: doc.data() as LocationData
                    }
                })
            locations.sort((loc1, loc2) => loc1.data.nameLower.localeCompare(loc2.data.nameLower))

            this._setAndPublishState({
                auth: this.state.auth,
                tags: this.state.tags,
                locations: locations
            })
        }
    }
}