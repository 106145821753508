









































import Vue from 'vue'
import Component from 'vue-class-component'
import EditSermonInfoPanel from '@/components/sermons/EditSermonInfoPanel.vue'
import * as api from '@/services/ApiService'
import appManager from '@/services/AppManager'
import { Prop, Ref } from 'vue-property-decorator'
import { extractResponseErrorMessage } from '@/services/ApiService'
import { SermonEditInfo } from '@/model/ComponentTypes'

@Component({
    components: {
        EditSermonInfoPanel
    }
})
export default class CreateSermonPanel extends Vue {
    @Prop()
    onCloseRequested: ((refresh: boolean) => void)|undefined
    @Ref('editSermonInfoPanel')
    editSermonInfoPanel!: EditSermonInfoPanel
    initialEditInfo: SermonEditInfo = {
        isSermonRefAutomatic: true,
        sermonRef: 0,
        title: '',
        bibleRefs: [ { start: 0, end: 0 } ],
        locations: [ { dateText: '', locationId: '' }]
    }
    formErrorMessage: string|null = null
    isCreating: boolean = false

    private async onCreatePressed() {
        const userId = appManager.userManager.state?.user?.id
        const info = this.editSermonInfoPanel.validateAndGetInfo()
        this.formErrorMessage = !info ? 'Please correct the error above' : null
        if (!userId || !info || this.formErrorMessage)
            return

        console.info(`title = ${info?.title}`)
        console.info('refs = ' + info?.bibleRefs?.map(r => r.start+'-'+r.end)?.join())
        console.info(`tags: ${info?.tagIds?.join()}`)
        console.info(`locations: ${info?.locations?.map(l => l.dateText+':'+l.locationId)?.join()}`)
        console.info(`notes: ${info?.notes?.text}`)

        this.isCreating = true
        try {
            await api.createSermon({
                userId: userId,
                isSermonRefAutomatic: info.isSermonRefAutomatic!,
                sermonRef: info.sermonRef,
                title: info.title,
                bibleRefs: info.bibleRefs,
                tagIds: info.tagIds,
                locations: info.locations,
                notes: info.notes
            })

            appManager.alertManager.publishSuccess('Sermon created successfully')

            if (this.onCloseRequested)
                this.onCloseRequested(true)
        } catch (error) {
            console.error(`Error Creating Sermon: ${JSON.stringify(error)}`)
            this.formErrorMessage = extractResponseErrorMessage(error) || 'There was an error creating the sermon. Please try again later.'
        }
        this.isCreating = false
    }

    private onCancelPressed() {
        if (this.onCloseRequested)
            this.onCloseRequested(false)
    }
}
