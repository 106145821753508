import firebase from 'firebase'

export type Firestore = firebase.firestore.Firestore
export type Timestamp = firebase.firestore.Timestamp
export type DocumentData = firebase.firestore.DocumentData
export type UpdateData = firebase.firestore.UpdateData
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot
export type DocumentReference = firebase.firestore.DocumentReference
export type Query = firebase.firestore.Query
export type QuerySnapshot = firebase.firestore.QuerySnapshot
export type Transaction = firebase.firestore.Transaction
export type WriteBatch = firebase.firestore.WriteBatch
export type FieldValue = firebase.firestore.FieldValue

export enum FirebaseAuthErrorCodes {
    INVALID_EMAIL = 'auth/invalid-email',
    USER_DISABLED = 'auth/user-disabled',
    USER_NOT_FOUND = 'auth/user-not-found',
    WRONG_PASSWORD = 'auth/wrong-password'
}

export function serverTimestamp(): FieldValue {
    return firebase.firestore.FieldValue.serverTimestamp()
}

export function fieldValueArrayUnion(...elements: any[]): FieldValue {
    return firebase.firestore.FieldValue.arrayUnion(...elements)
}

export function fieldValueArrayRemove(...elements: any[]): FieldValue {
    return firebase.firestore.FieldValue.arrayRemove(...elements)
}

export function newTimestampNow(): Timestamp {
    return firebase.firestore.Timestamp.now()
}

export function newTimestampFromMillis(milliseconds: number): Timestamp {
    return firebase.firestore.Timestamp.fromMillis(milliseconds)
}

export function newTimestampFromSecs(seconds: number): Timestamp {
    return newTimestampFromMillis(seconds * 1000)
}

export function newTimestampFromMillisOrNull(milliseconds: number|undefined|null): Timestamp|undefined {
    if (milliseconds === undefined || milliseconds === null)
        return undefined
    return newTimestampFromMillis(milliseconds)
}

export function newTimestampFromSecsOrNull(seconds: number|undefined|null): Timestamp|undefined {
    if (seconds === undefined || seconds === null)
        return undefined
    return newTimestampFromSecs(seconds)
}

export function newFieldValueIncrement(delta: number): FieldValue {
    return firebase.firestore.FieldValue.increment(delta)
}

export function timestampToMillis(timestamp: Timestamp|FieldValue): number {
    const t = timestamp as Timestamp
    return t?.toMillis() || 0
}

/**
 * This class is a typed DocumentSnapshot wrapper which lazily converts the document data only when first accessed
 */
export class DocSnapTyped<T> {
    public doc: DocumentSnapshot
    private readonly dataConverter: (data: DocumentData) => T|undefined
    private isDataConverted: boolean
    private dataValue: T|undefined

    constructor(doc: DocumentSnapshot, dataConverter: (data: DocumentData) => T|undefined) {
        this.doc = doc
        this.dataConverter = dataConverter
        this.isDataConverted = false
    }

    get id(): string {
        return this.doc.id
    }

    get ref(): DocumentReference {
        return this.doc.ref
    }

    get exists(): boolean {
        return this.doc.exists
    }

    get data(): T|undefined {
        if (!this.isDataConverted && this.exists) {
            const docData = this.doc.data()
            if (docData)
                this.dataValue = this.dataConverter(docData)
            this.isDataConverted = true
        }

        return this.dataValue
    }
}

export class QuerySnapTyped<T> {
    constructor(public readonly docs: Array<DocSnapTyped<T>>) {
    }

    static fromSnapshot<T>(snapshot: QuerySnapshot, dataConverter: (data: DocumentData) => T|undefined): QuerySnapTyped<T> {
        const docs = snapshot.docs.map(doc => new DocSnapTyped<T>(doc, dataConverter))
        return new QuerySnapTyped<T>(docs)
    }

    get empty(): boolean {
        return this.docs.length === 0
    }

    get size(): number {
        return this.docs.length
    }
}