














import Vue from 'vue'
import Component from 'vue-class-component'
import { Subscription as RxSubscription } from 'rxjs'
import { AlertMessage, AlertMessageType } from '@/services/AlertManager'
import appManager from '@/services/AppManager'

@Component
export default class AlertMessageCenter extends Vue {
    isMessageVisible: boolean = false
    message: AlertMessage|null = null
    messageCounter: number = 0
    alertSubscription: RxSubscription|null = null

    created() {
        this.alertSubscription = appManager.alertManager.stateStream.subscribe(message => { this.onMessageReceived(message) })
    }

    // noinspection JSUnusedGlobalSymbols
    destroyed() {
        if (this.alertSubscription)
            this.alertSubscription.unsubscribe()
    }

    private onMessageReceived(message: AlertMessage) {
        this.message = message
        this.isMessageVisible = !!message && message.type !== AlertMessageType.NONE
        this.messageCounter += 1
    }

    private getMessageTimeout(): number {
        return (this.message?.type === AlertMessageType.ERROR) ? 8000 : 4000
    }

    private getMessageColor(): string {
        const type = this.message?.type
        if (type === AlertMessageType.SUCCESS)
            return 'green'
        if (type === AlertMessageType.ERROR)
            return 'error'

        return 'info'
    }
}
