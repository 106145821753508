





























import Component from 'vue-class-component'
import { AuthState, AuthStateData } from '@/services/UserManager'
import { Subscription } from 'rxjs'
import appManager from '@/services/AppManager'
import { isValidEmail } from '@/core/Utils'
import firebase from 'firebase'
import { FirebaseAuthErrorCodes } from '@/core/FirebaseTypes'
import FormTextField from '@/components/FormTextField.vue'
import { RoutePaths } from '@/router'
import { BaseVueComponent } from '@/components/BaseVueComponent'

@Component({
    components: {
        FormTextField
    }
})
export default class LoginPanel extends BaseVueComponent {
    state: AuthStateData|null = null
    email: string|null = null
    password: string|null = null
    emailErrorMessage: string|null = null
    passwordErrorMessage: string|null = null
    loginErrorMessage = ''
    stateSubscription: Subscription|undefined

    // noinspection JSUnusedGlobalSymbols
    created() {
        this.stateSubscription = appManager.userManager.stateStream.subscribe(state =>
            this.onAuthStateChanged(state)
        )
    }

    // noinspection JSUnusedGlobalSymbols
    destroyed() {
        if (this.stateSubscription)
            this.stateSubscription.unsubscribe()
    }

    isLoggingIn(): boolean {
        return this.state?.state === AuthState.LOGGING_IN
    }

    private onAuthStateChanged(state: AuthStateData) {
        console.log(`Auth State: ${state?.state}`)
        this.state = state
    }

    async onLogInPressed() {
        const email = this.email?.trim()
        const password = this.password
        if (!email)
            this.emailErrorMessage = 'Must specify an email'
        else if (!isValidEmail(email))
            this.emailErrorMessage = 'Invalid email format'
        else
            this.emailErrorMessage = null
        this.passwordErrorMessage = password ? null : 'Must specify a password'

        if (email && password && !this.emailErrorMessage && !this.passwordErrorMessage) {
            try {
                await appManager.userManager.signIn(email, password)

                // remove the extra query parameters
                await this.replaceRouteIfNew(RoutePaths.PORTAL_HOME)
            } catch (error) {
                const firebaseError = error as firebase.FirebaseError
                if (firebaseError.code === FirebaseAuthErrorCodes.INVALID_EMAIL ||
                    firebaseError.code === FirebaseAuthErrorCodes.USER_NOT_FOUND ||
                    firebaseError.code === FirebaseAuthErrorCodes.WRONG_PASSWORD) {
                    this.loginErrorMessage = 'Invalid email or password'
                } else {
                    this.loginErrorMessage = error.message || 'Invalid email or password'
                }
            }
        }
    }
}
