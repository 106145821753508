













import Component from 'vue-class-component'
import { BaseVueComponent } from '@/components/BaseVueComponent'
import { Prop } from 'vue-property-decorator'
import { TextDocumentData } from '@/core/Types'

@Component
export default class FormTextDocument extends BaseVueComponent {
    @Prop()
    value: TextDocumentData|undefined
    @Prop({ type: String })
    label: string|undefined
    @Prop({ type: String })
    errorMessage: string|undefined

    getTextValue(): string|undefined {
        return this.value?.text
    }

    onValueChanged(text: string|undefined) {
        this.emitValueChanged({
            text: text
        })
    }
}
