import { BibleRefRange, Sermon, SermonData, SermonLocationData, TextDocumentData, UserRole } from './Types'
import { newTimestampFromMillis, timestampToMillis } from './FirebaseTypes'

/**
 * NOTE: API interface types cannot use Firebase Timestamp types as they do not automatically
 *       serialize to the appropriate object types when converting to/from JSON. Instead,
 *       for API types, simple milliseconds will be used. API-specific data types which have
 *       similar names as the standard DB "Data" types will have the "Dto" suffix.
 */

export interface IdDto {
    id: string
}

export interface TimestampDto extends IdDto {
    createdMillis: number
}

export interface SermonDto extends TimestampDto {
    userId: string
    sermonRef: number                       // numerical counter for sermons
    title: string
    bibleRefs?: BibleRefRange[]
    tagIds?: string[]
    locations?: SermonLocationData[]
    earliestLocationDateText?: string       // YYYY-MM-DD - earliest by date, not array order
    latestLocationDateText?: string         // YYYY-MM-DD - latest by date, not array order
    notes?: TextDocumentData
}

export interface CreateUserRequest {
    firstName: string
    lastName: string
    email: string
    password: string
    role: UserRole
}

export interface CreateUserResponse {
    id: string
}

export interface EditUserRequest {
    userId: string
    newFirstName?: string               // if first name is specified, last name must be specified
    newLastName?: string                // if last name is specified, first name must be specified
    newEmail?: string
    newRole?: UserRole
    newOneDrive?: EditUserOneDriveData
}

export interface EditUserOneDriveData {
    newSermonsRootFolderPath?: string
}

export interface CreateLocationRequest {
    userId?: string                     // use principal if undefined
    name: string
}

export interface CreateLocationResponse {
    id: string
}

export interface EditLocationRequest {
    locationId: string
    newName?: string
}

export interface CreateTagRequest {
    userId?: string                     // use principal if undefined
    name: string
}

export interface CreateTagResponse {
    id: string
}

export interface EditTagRequest {
    tagId: string
    newName?: string
}

export interface CreateSermonRequest {
    userId: string
    isSermonRefAutomatic: boolean           // true to automatically assign a 'sermonRef' number
    sermonRef: number                       // numerical counter for sermons
    title: string
    bibleRefs?: BibleRefRange[]
    tagIds?: string[]
    locations?: SermonLocationData[]
    notes?: TextDocumentData
}

export interface CreateSermonResponse {
    id: string
}

export interface EditSermonRequest {
    sermonId: string
    newSermonRef?: number
    newTitle?: string
    newBibleRefs?: BibleRefRange[]
    newTagIds?: string[]
    newLocations?: SermonLocationData[]
    newNotes?: TextDocumentData
}

export interface SearchSermonsRequest {
    userId: string
    sermonRefStart?: number
    sermonRefEnd?: number
    title?: string
    bibleRef?: BibleRefRange
    tagId?: string
    locationId?: string
    dateTextStart?: string      // YYYY-MM-DD format
    dateTextEnd?: string        // YYYY-MM-DD format
    limit?: number
    startAfterSermonId?: string
}

export interface SearchSermonsResponse {
    sermons: SermonDto[]
}

export interface OneDriveProcessAuthCodeRequest {
    scope: string               // space-separated list of scopes
    redirectUri: string
    code: string
}

export interface OneDriveRefreshTokensRequest {
    userId: string
}

export interface OneDriveAuthResponse {
    accessToken: string
    expiresAtMillis: number     // expiration time of the accessToken
}

export function convertSermonDocToDto(id: string, data: SermonData): SermonDto {
    return {
        id: id,
        createdMillis: timestampToMillis(data.createdTimestamp),
        userId: data.userId,
        sermonRef: data.sermonRef,
        title: data.title,
        bibleRefs: data.bibleRefs,
        tagIds: data.tagIds,
        locations: data.locations,
        earliestLocationDateText: data.earliestLocationDateText,
        latestLocationDateText: data.latestLocationDateText,
        notes: data.notes
    }
}

export function convertSermonDtoToDoc(dto: SermonDto): Sermon {
    return {
        id: dto.id,
        data: {
            createdTimestamp: newTimestampFromMillis(dto.createdMillis),
            userId: dto.userId,
            sermonRef: dto.sermonRef,
            title: dto.title,
            bibleRefs: dto.bibleRefs,
            tagIds: dto.tagIds,
            locations: dto.locations,
            earliestLocationDateText: dto.earliestLocationDateText,
            latestLocationDateText: dto.latestLocationDateText,
            notes: dto.notes
        }
    }
}