import { UserRole } from '@/core/Types'
import { FieldValue, Timestamp } from '@/core/FirebaseTypes'
import { toStringMDYY } from '@/core/DateUtils'

export function getUserRoleTitle(role: UserRole): string {
    if (role === UserRole.ADMIN)
        return 'Admin'

    return 'User'
}

export function getUserRoleDescription(role: UserRole): string {
    if (role === UserRole.ADMIN)
        return 'System-wide admin'

    return 'Normal user'
}

export function getDateText(timestamp: Timestamp|FieldValue): string {
    const millis = (timestamp as Timestamp)?.toMillis()
    return getDateTextFromMillis(millis)
}

export function getDateTextFromMillis(millis: number|null|undefined): string {
    if (millis === null || millis === undefined)
        return '---'

    return toStringMDYY(new Date(millis))
}

/**
 * Returns the current full path without any parameters.
 * Example:
 *   Current:  http://localhost:9090/page1&param=1
 *   Returned: http://localhost:9090/page1
 */
export function getHostPathWithoutParams(): string {
    return `${window.location.protocol}//${window.location.host}${window.location.pathname}`
}