



































































import Vue from 'vue'
import Component from 'vue-class-component'
import Spinner from '@/components/Spinner.vue'
import { Location } from '@/core/Types'
import appManager from '@/services/AppManager'
import { Subscription } from 'rxjs'
import { UserMetadataState } from '@/services/UserMetadataManager'
import CreateLocationDialog from '@/components/locations/CreateLocationDialog.vue'
import EditLocationDialog from '@/components/locations/EditLocationDialog.vue'

@Component({
    components: {
        Spinner,
        CreateLocationDialog,
        EditLocationDialog
    }
})
export default class LocationsView extends Vue {
    // locations
    userMetadataSnapshotCounter: number = 0 // simple way to force a refresh when new items arrive
    locations: Location[] = []
    userMetadataSubscription: Subscription|null = null

    // create location dialog
    isCreateLocationDialogVisible: boolean = false
    createLocationDialogCounter: number = 0 // simple way to reset the dialog each time it is opened

    // edit location dialog
    isEditLocationDialogVisible: boolean = false
    editLocation: Location|null = null
    editLocationDialogCounter: number = 0 // simple way to reset the dialog before opening each time

    created() {
        this.userMetadataSubscription = appManager.userMetadataManager.stateStream.subscribe(
            state => this.onUserMetadataChanged(state)
        )
    }

    destroyed() {
        if (this.userMetadataSubscription) {
            this.userMetadataSubscription.unsubscribe()
            this.userMetadataSubscription = null
        }
    }

    onUserMetadataChanged(state: UserMetadataState) {
        this.locations = state.locations
        this.userMetadataSnapshotCounter++
    }

    onAddLocationPressed() {
        this.createLocationDialogCounter += 1
        this.isCreateLocationDialogVisible = true
    }

    onCloseCreateLocationDialogRequested() {
        this.isCreateLocationDialogVisible = false
    }

    onEditLocationPressed(location: Location) {
        this.editLocationDialogCounter += 1
        this.editLocation = location
        this.isEditLocationDialogVisible = true
    }

    onCloseEditLocationDialogRequested() {
        this.isEditLocationDialogVisible = false
    }
}
