


























import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { BaseVueComponent } from '@/components/BaseVueComponent'
import { convertDateTextMMDDYYYYToYYYYMMDD, convertDateTextYYYYMMDDToMMDDYYYY } from '@/core/DateUtils'

/**
 * Text field component which shows a date formatted as MM/DD/YYYY and allows the user
 * to change it by typing or clicking the calendar to display a date picker. The value
 * should be a date text of format YYYY-MM-DD.
 */
@Component
export default class FormDateTextField extends BaseVueComponent {
    // YYYY-MM-DD date text (start of ISO 8601 format)
    @Prop({ type: String })
    value: string|undefined
    @Prop()
    onEnterPressed: (() => void)|undefined

    // MM/DD/YYYY formatted date which is displayed
    dateFormatted: string|null = null
    isMenuVisible: boolean = false

    created() {
        this.dateFormatted = convertDateTextYYYYMMDDToMMDDYYYY(this.value)
    }

    private onDateChanged(newDate: string|undefined|null) {
        this.isMenuVisible = false
        this.dateFormatted = convertDateTextYYYYMMDDToMMDDYYYY(newDate)
        this.emitValueChanged(newDate)
    }

    private onDateTextChanged(newDateText: string|undefined) {
        const newDate = convertDateTextMMDDYYYYToYYYYMMDD(newDateText)
        this.onDateChanged(newDate)
    }

    private onKeyUpEnter() {
        if (this.onEnterPressed)
            this.onEnterPressed()
    }
}
