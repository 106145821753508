















































































import Vue from 'vue'
import Component from 'vue-class-component'
import * as api from '@/services/ApiService'
import { getHostPathWithoutParams } from '@/model/Helpers'
import Spinner from '@/components/Spinner.vue'
import SelectOneDriveItemDialog from '@/components/onedrive/SelectOneDriveItemDialog.vue'
import { Subscription } from 'rxjs'
import appManager from '@/services/AppManager'
import { AuthStateData } from '@/services/UserManager'

const msalScope = 'files.read files.read.all sites.read.all offline_access'

@Component({
    components: {
        Spinner,
        SelectOneDriveItemDialog
    }
})
export default class OneDriveView extends Vue {
    // user auth state
    authState: AuthStateData|null = null
    authStateSnapshotCounter: number = 0 // simple way to force a refresh when new items arrive
    authStateSubscription: Subscription|null = null

    // select root path dialog
    isSelectRootPathDialogVisible: boolean = false
    selectRootPathDialogCounter: number = 0 // simple way to reset the dialog each time it is opened
    isSavingRootPath: boolean = false

    isRedeemingAuthCode: boolean = false

    async created() {
        this.authStateSubscription = appManager.userManager.stateStream.subscribe(
            state => this.onUserAuthStateChanged(state)
        )

        // complete the auth process if we are being redirected
        await this.completeAuthProcess()
    }

    destroyed() {
        if (this.authStateSubscription) {
            this.authStateSubscription.unsubscribe()
            this.authStateSubscription = null
        }
    }

    private async completeAuthProcess(): Promise<void> {
        // after the OneDrive auth process has completed it is redirected to this page with an
        // authorization 'code' query parameter. if that parameter exists, complete the auth
        // process and remove that parameter.
        const code = this.$route.query.code as string
        if (code) {
            this.isRedeemingAuthCode = true
            try {
                await api.oneDriveProcessAuthCode({
                    scope: msalScope,
                    redirectUri: getHostPathWithoutParams(),
                    code: code
                })
            } catch (error) {
                console.error(`Error Redeeming Auth Code: ${JSON.stringify(error)}`)
                appManager.alertManager.publishError('There was an error completing the authorization process. Please try again later.')
            }
            this.isRedeemingAuthCode = false

            // remove the auth code parameter
            await this.$router.push({})
        }
    }

    private onUserAuthStateChanged(state: AuthStateData) {
        this.authState = state
        this.authStateSnapshotCounter++
    }

    private isOneDriveAuthorized(): boolean {
        return !!this.authState?.user?.data?.oneDrive?.accessToken
    }

    private getOneDriveSermonsRootFolderPath(): string|undefined {
        return this.authState?.user?.data?.oneDrive?.sermonsRootFolderPath
    }

    private async onStartAuthPressed() {
        const clientId = '0001d700-6253-4823-aed9-24fc6f1776aa'
        const redirectUrl = getHostPathWithoutParams()
        const responseType = 'code'
        const responseMode = 'query'
        const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?`
            + `client_id=${encodeURIComponent(clientId)}`
            + `&response_type=${encodeURIComponent(responseType)}`
            + `&redirect_uri=${encodeURIComponent(redirectUrl)}`
            + `&response_mode=${encodeURIComponent(responseMode)}`
            + `&scope=${encodeURIComponent(msalScope)}`
        window.location.replace(url)
    }

    private onSelectRootPathPressed() {
        this.isSelectRootPathDialogVisible = true
        this.selectRootPathDialogCounter++
    }

    private async onCloseSelectRootPathDialogRequested(selectedFolderPath: string|null) {
        console.info(`Selected Root Path: ${selectedFolderPath}`)
        this.isSelectRootPathDialogVisible = false
        const userId = this.authState?.user?.id
        if (selectedFolderPath && userId) {
            this.isSavingRootPath = true
            try {
                await api.editUser({
                    userId: userId,
                    newOneDrive: {
                        newSermonsRootFolderPath: selectedFolderPath
                    }
                })
            } catch (error) {
                console.error(`Error Updating Folder: ${JSON.stringify(error)}`)
                appManager.alertManager.publishError('There was an error updating your sermons folder. Please try again later.')
            }
            this.isSavingRootPath = false
        }
    }
}
