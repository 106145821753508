import Vue from 'vue'
import Component from 'vue-class-component'
import { Route } from 'vue-router'

@Component
export class BaseVueComponent extends Vue {
    async pushRouteIfNew(path: string): Promise<Route> {
        let route = this.$router.currentRoute
        if (route?.fullPath !== path)
            route = await this.$router.push(path)
        return route
    }

    async replaceRouteIfNew(path: string): Promise<Route> {
        let route = this.$router.currentRoute
        if (route?.fullPath !== path)
            route = await this.$router.replace(path)
        return route
    }

    protected emitValueChanged(...args: any[]) {
        this.$emit('input', ...args)
    }
}