















import Component from 'vue-class-component'
import { BaseVueComponent } from '@/components/BaseVueComponent'
import { Prop } from 'vue-property-decorator'
import { Location, SermonLocationData } from '@/core/Types'
import FormDateTextField from '@/components/FormDateTextField.vue'

@Component({
    components: {
        FormDateTextField
    }
})
export default class EditSermonLocationPanel extends BaseVueComponent {
    @Prop({ required: true })
    value: SermonLocationData|undefined
    @Prop({ required: true })
    locations: Location[]|undefined

    private onDateChanged(newDateText: string|undefined) {
        this.emitNewValue(newDateText, this.value?.locationId)
    }

    private onLocationChanged(locationId: string|undefined) {
        this.emitNewValue(this.value?.dateText, locationId)
    }

    private emitNewValue(dateText: string|undefined, locationId: string|undefined) {
        const newValue: SermonLocationData = {
            dateText: dateText || '',
            locationId: locationId || ''
        }
        this.emitValueChanged(newValue)
    }
}
