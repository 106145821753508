





































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Location, SermonLocationData, Tag, TextDocumentData } from '@/core/Types'
import FormTextField from '@/components/FormTextField.vue'
import FormTextDocument from '@/components/FormTextDocument.vue'
import BibleRefRangeComponent from '@/components/BibleRefRangeComponent.vue'
import EditSermonLocationPanel from '@/components/locations/EditSermonLocationPanel.vue'
import { BibleRefRangeEditInfo, SermonEditInfo } from '@/model/ComponentTypes'
import { UserMetadataState } from '@/services/UserMetadataManager'
import { Subscription } from 'rxjs'
import appManager from '@/services/AppManager'
import { isBlank } from '@/core/Utils'
import { convertStringToInt } from '@/core/StringUtils'

@Component({
    components: {
        FormTextDocument,
        FormTextField,
        BibleRefRangeComponent,
        EditSermonLocationPanel
    }
})
export default class EditSermonInfoPanel extends Vue {
    @Prop({ required: true, type: Boolean })
    isCreateOrEdit: boolean|undefined           // true=creating new sermon; false=editing existing sermon
    @Prop()
    initialEditInfo: SermonEditInfo|undefined
    sermonRefMode: number = 0                   // 0=automatic; 1=manual
    sermonRefText: string = ''
    sermonRefTextErrorMessage: string|null = null
    title: string|null = null
    titleErrorMessage: string|null = null
    bibleRefRanges: BibleRefRangeEditInfo[] = []
    bibleRefRangesErrorMessage: string|null = null
    locationsErrorMessage: string|null = null
    notes: TextDocumentData|null = null
    notesErrorMessage: string|null = null

    // user metadata
    userMetadataSnapshotCounter: number = 0 // simple way to force a refresh when new items arrive
    userMetadataSubscription: Subscription|null = null

    // Tags - the final truth is 'selectedTagIds', with 'availableTags' containing all available tags for
    //        the user, and 'selectedTags' being a combined list from 'selectedTagIds' + 'availableTags'.
    selectedTagIds: string[] = []
    selectedTags: Tag[] = []
    availableTags: Tag[] = []

    // Locations
    locations: SermonLocationData[] = []
    availableLocations: Location[] = []

    created() {
        // make a deep clone of the edit info prop so this object can be edited
        if (this.initialEditInfo) {
            this.sermonRefMode = (this.initialEditInfo.isSermonRefAutomatic) ? 0 : 1
            this.sermonRefText = (this.initialEditInfo.isSermonRefAutomatic) ? '' : (this.initialEditInfo.sermonRef?.toString() || '')
            this.title = this.initialEditInfo.title
            this.bibleRefRanges = this.initialEditInfo.bibleRefs
                ?.map(ref => BibleRefRangeEditInfo.fromStartEnd(ref.start, ref.end)) || []
            this.selectedTagIds = this.initialEditInfo.tagIds || []
            this.locations = this.initialEditInfo.locations || []
            this.notes = this.initialEditInfo.notes || null
        }

        this.userMetadataSubscription = appManager.userMetadataManager.stateStream.subscribe(s => this.onUserMetadataChanged(s))
    }

    destroyed() {
        if (this.userMetadataSubscription) {
            this.userMetadataSubscription.unsubscribe()
            this.userMetadataSubscription = null
        }
    }

    validateAndGetInfo(): SermonEditInfo|undefined {
        if (!this.validatePage())
            return undefined

        const isSermonRefAutomatic = (this.sermonRefMode === 0)
        const sermonRef = isSermonRefAutomatic ? 0 : convertStringToInt(this.sermonRefText)!

        return {
            isSermonRefAutomatic: isSermonRefAutomatic,
            sermonRef: sermonRef,
            title: this.title!,
            bibleRefs: this.bibleRefRanges.map(info => info.toBibleRefRange()!),
            tagIds: this.selectedTagIds,
            locations: this.locations,
            notes: this.notes || undefined
        }
    }

    private validatePage(): boolean {
        let isSermonRefValid = true
        if (this.sermonRefMode === 1) {
            const sermonRef = convertStringToInt(this.sermonRefText)
            isSermonRefValid = (sermonRef !== null)
        }
        this.sermonRefTextErrorMessage = !isSermonRefValid ? 'Specify a reference number' : null
        this.titleErrorMessage = isBlank(this.title) ? 'Specify a title' : null
        this.bibleRefRangesErrorMessage = this.bibleRefRanges.some(info => !info.isValid) ? 'Invalid Bible Reference' : null
        this.locationsErrorMessage = this.locations.some(loc => !this.isValidLocation(loc)) ? 'Invalid location and/or date' : null

        return !this.sermonRefTextErrorMessage && !this.titleErrorMessage && !this.bibleRefRangesErrorMessage
    }

    private isValidLocation(location: SermonLocationData|null): boolean {
        if (!location)
            return false

        // do not validate the date
        return !!location.locationId
    }

    private onUserMetadataChanged(state: UserMetadataState) {
        this.availableTags = state.tags
        this.selectedTags = this.selectedTagIds
            .map(tagId => state.tags.findIndex(t => t.id === tagId))
            .filter(index => index >= 0)
            .map(index => state.tags[index])

        this.availableLocations = state.locations
        this.userMetadataSnapshotCounter++
    }

    getBibleRefRangeKey(item: BibleRefRangeEditInfo, index: number): string {
        return `${index}_${item}`
    }

    private onAddBibleRefPressed() {
        this.bibleRefRanges.push(BibleRefRangeEditInfo.empty())
    }

    private onBibleRefChanged(index: number, newValue: BibleRefRangeEditInfo) {
        if (index >= 0 && index < this.bibleRefRanges.length && newValue) {
            console.info(`onBibleRefChanged: ${index}  ${newValue}`)
            this.bibleRefRanges.splice(index, 1, newValue)
        }
    }

    private onRemoveBibleRefAt(index: number) {
        if (index >= 0 && index < this.bibleRefRanges.length) {
            console.info(`Removing Bible Ref Index: ${index}`)
            this.bibleRefRanges.splice(index, 1)
        }
    }

    private onSelectedTagsChanged(newSelectedTagIds: string[]|undefined) {
        this.selectedTagIds = newSelectedTagIds || []
        console.info(`newSelectedTagsId: ${newSelectedTagIds}`)
    }

    private onAddLocationPressed() {
        const newLocation: SermonLocationData = {
            dateText: '',
            locationId: ''
        }

        this.locations.push(newLocation)
    }

    private onLocationChanged(index: number, newValue: SermonLocationData) {
        if (index >= 0 && index < this.locations.length && newValue) {
            console.info(`onLocationChanged: ${newValue.dateText} - ${newValue.locationId}`)
            this.locations.splice(index, 1, newValue)
        }
    }

    private onRemoveLocationAt(index: number) {
        if (index >= 0 && index < this.locations.length) {
            console.info(`Removing Location Index: ${index}`)
            this.locations.splice(index, 1)
        }
    }
}
