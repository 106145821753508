import { BehaviorSubject, Observable } from 'rxjs'

/**
 * Base class for other classes which provide an observable state
 */
export class BaseStateManager<TState extends object> {
    private readonly _stateSubject: BehaviorSubject<TState>

    protected constructor(protected _state: TState) {
        this._stateSubject = new BehaviorSubject<TState>(_state)
    }

    public get state(): TState {
        return this._state
    }

    public get stateStream(): Observable<TState> {
        return this._stateSubject
    }

    protected _setAndPublishState(state: TState) {
        this._state = state
        this._publishState()
    }

    protected _publishState() {
        // publish clones of the state so the local state can be modified
        this._stateSubject.next(Object.create(this._state))
    }
}