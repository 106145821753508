





































import Vue from 'vue'
import Component from 'vue-class-component'
import { RoutePaths } from '@/router'

@Component
export default class HomeView extends Vue {
    getButtonHeight(): string {
        return this.$vuetify.breakpoint.smAndUp ? '140px' : '90px'
    }

    get sermonsPath(): string {
        return RoutePaths.SERMONS
    }

    get tagsPath(): string {
        return RoutePaths.TAGS
    }

    get locationsPath(): string {
        return RoutePaths.LOCATIONS
    }

    get myProfilePath(): string {
        return RoutePaths.MY_PROFILE
    }

    get oneDrivePath(): string {
        return RoutePaths.ONEDRIVE
    }
}
