










































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import appManager from '@/services/AppManager'
import { DriveItem } from '@microsoft/microsoft-graph-types'
import { GraphError } from '@microsoft/microsoft-graph-client'
import Spinner from '@/components/Spinner.vue'

@Component({
    components: {
        Spinner
    }
})
export default class SelectOneDriveItemDialog extends Vue {
    @Prop({ required: true })
    value: boolean|undefined // true to show the dialog
    @Prop({ required: true })
    onCloseRequested: ((selectedFolderPath: string|null, selectedFile: DriveItem|null) => void)|undefined
    @Prop({ required: true, type: String })
    title: string|undefined
    @Prop()
    initialPath: string|undefined
    @Prop({ type: Boolean, default: false })
    selectFolderOnly: boolean|undefined
    @Prop({ type: Boolean, default: false })
    selectFileOnly: boolean|undefined
    isLoading: boolean = false
    loadErrorMessage: string|null = null
    folderPath: string|null = null
    folderDriveItems: DriveItem[] = []

    created() {
        this.folderPath = this.initialPath || '/'
        this.loadSelectedPathDriveItems()
    }

    private async loadSelectedPathDriveItems() {
        this.isLoading = true

        try {
            const items = await appManager.graphService.getDrivePathChildren(this.folderPath || undefined)
            this.folderDriveItems = items || []
            // only show folders if the user can only select folders
            if (this.selectFolderOnly)
                this.folderDriveItems = this.folderDriveItems.filter(item => !!item.folder)
            this.loadErrorMessage = null
        } catch (error) {
            const graphError = error as GraphError
            this.loadErrorMessage = graphError.message
            this.folderDriveItems = []
        }

        this.isLoading = false
    }

    private isFolderPathRoot(): boolean {
        return this.folderPath === '/'
    }

    private onNavigateUpFolderPressed() {
        let newFolderPath: string = '/'
        if (this.folderPath) {
            const index = this.folderPath.lastIndexOf('/')
            if (index >= 0)
                newFolderPath = this.folderPath.substring(0, index)
        }
        if (!newFolderPath)
            newFolderPath = '/'

        this.folderPath = newFolderPath
        this.loadSelectedPathDriveItems()
    }

    private getDriveItemIcon(item: DriveItem) {
        if (item.folder)
            return 'mdi-folder-outline'

        let ext: string|undefined
        if (item.name) {
            const extStartIndex = item.name.lastIndexOf('.')
            if (extStartIndex >= 0 && extStartIndex < (item.name.length-1))
                ext = item.name.substr(extStartIndex+1).trim().toLowerCase()
        }

        if (ext === 'ppt' || ext === 'pptx')
            return 'mdi-microsoft-powerpoint'
        if (ext === 'doc' || ext === 'docx')
            return 'mdi-microsoft-word'
        if (ext === 'jpg' || ext === 'jpeg' || ext === 'png')
            return 'mdi-image-outline'

        return 'mdi-file-outline'
    }

    private getLocaleDateTimeString(isoDateTimeText: string|undefined): string {
        if (!isoDateTimeText)
            return ''

        const date = new Date(isoDateTimeText)
        return `${date.toLocaleString()}`
    }

    private async onDriveItemSelected(item: DriveItem) {
        // if this is a file, open it
        if (!item.folder) {
            if (this.onCloseRequested)
                this.onCloseRequested(null, item)
            return
        }

        if (!this.folderPath) {
            this.folderPath = `/${item.name}`
        } else {
            if (!this.folderPath.endsWith('/'))
                this.folderPath += '/'
            this.folderPath += item.name
        }
        await this.loadSelectedPathDriveItems()
    }

    private onSelectPressed() {
        if (this.onCloseRequested)
            this.onCloseRequested(this.folderPath, null)
    }

    private onCancelPressed() {
        if (this.onCloseRequested)
            this.onCloseRequested(null, null)
    }
}
