export const MILLIS_PER_DAY = 86_400_000

/**
 * Converts the days to milliseconds
 */
export function daysToMillis(days: number): number {
    return hoursToMillis(days * 24)
}

/**
 * Converts the hours to milliseconds
 */
export function hoursToMillis(hours: number): number {
    return minsToMillis(hours * 60)
}

/**
 * Converts the minutes to milliseconds
 */
export function minsToMillis(mins: number): number {
    return secsToMillis(mins * 60)
}

/**
 * Converts the seconds to milliseconds
 */
export function secsToMillis(secs: number): number {
    return secs * 1000
}

/**
 * Converts the seconds to milliseconds if not null or returns null
 */
export function secsToMillisOrNull(secs: number|null): number|null {
    return (secs === null) ? null : secsToMillis(secs)
}

/**
 * Returns a string representation of the date as: M/D/YY
 */
export function toStringMDYY(date: Date): string {
    const month = (date.getMonth()+1).toString()
    const day = date.getDate().toString()
    const year = (date.getFullYear() % 100).toString().padStart(2, '0')

    return `${month}/${day}/${year}`
}

/**
 * Converts the date to 'YYYY-MM-DD'
 * @param date Date to convert
 */
export function toStringYYYYMMDD(date: Date): string {
    return date.toISOString().substr(0, 10)
}

/**
 * Converts the date to 'HH:MM'
 * @param date Date to convert
 */
export function toStringHHM(date: Date): string {
    return date.toISOString().substr(11, 5)
}

/**
 * Converts the specified date which is assumed to be UTC to Local time
 * @param date Date to convert
 */
export function convertDateUtcToLocal(date: Date): Date {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60_000)
}

/**
 * Converts the specified date which is assumed to be Local to UTC time
 * @param date Date to convert
 */
export function convertDateLocalToUtc(date: Date): Date {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60_000)
}

/**
 * Converts date text from 'YYYY-MM-DD' to 'MM/DD/YYYY'
 */
export function convertDateTextYYYYMMDDToMMDDYYYY(dateText: string|null|undefined): string|null {
    if (!dateText)
        return null

    const [year, month, day] = dateText.split('-')
    if (!month || !day || !year)
        return null
    return `${month}/${day}/${year}`
}

/**
 * Converts date text from 'MM/DD/YYYY' or 'MM/DD/YY' to 'YYYY-MM-DD'. Any input date
 * which has a 2-digit year 'MM/DD/YY' is converted to 'MM/DD/20YY' assuming a 20xx date.
 */
export function convertDateTextMMDDYYYYToYYYYMMDD(dateText: string|null|undefined): string|null {
    if (!dateText)
        return null

    const [month, day, year] = dateText.split('/')
    if (!month || !day || !year)
        return null

    const yearPadded = (year.length == 2) ? `20${year}` : year.padStart(4, '0')
    const monthPadded = month.padStart(2, '0')
    const dayPadded = day.padStart(2, '0')
    return `${yearPadded}-${monthPadded}-${dayPadded}`
}

/**
 * Checks if the specified date is after the expiration year and month (end of month)
 * @param date Date to check
 * @param expireYear Expiration year
 * @param expireMonth Expiration month (end of month)
 */
export function isDateAfterYearMonth(date: Date, expireYear: number, expireMonth: number): boolean {
    const year = date.getFullYear()
    const month = date.getMonth() + 1

    return (year > expireYear || (year == expireYear && month >= expireMonth))
}