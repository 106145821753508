import { UserManager } from "@/services/UserManager"
import { AlertManager } from '@/services/AlertManager'
import { UserMetadataManager } from '@/services/UserMetadataManager'
import { MicrosoftGraphService } from '@/services/MicrosoftGraphService'

export default class AppManager {
    static userManager: UserManager
    static userMetadataManager: UserMetadataManager
    static graphService: MicrosoftGraphService
    static alertManager: AlertManager

    static initialize(userManager: UserManager,
                      userMetadataManager: UserMetadataManager,
                      graphService: MicrosoftGraphService,
                      alertManager: AlertManager) {
        AppManager.userManager = userManager
        AppManager.userMetadataManager = userMetadataManager
        AppManager.graphService = graphService
        AppManager.alertManager = alertManager
    }

    static get appVersion(): string {
        return process.env.VUE_APP_VERSION || 'unk'
    }
}