













































import Vue from 'vue'
import Component from 'vue-class-component'
import EditSermonInfoPanel from '@/components/sermons/EditSermonInfoPanel.vue'
import * as db from '@/core/Database'
import * as api from '@/services/ApiService'
import appManager from '@/services/AppManager'
import { Prop, Ref } from 'vue-property-decorator'
import { extractResponseErrorMessage } from '@/services/ApiService'
import { SermonEditInfo } from '@/model/ComponentTypes'
import { Sermon } from '@/core/Types'
import Spinner from '@/components/Spinner.vue'

@Component({
    components: {
        Spinner,
        EditSermonInfoPanel
    }
})
export default class EditSermonPanel extends Vue {
    @Prop({ required: true, type: String })
    sermonId!: string
    @Prop()
    sermon: Sermon|undefined
    @Prop({ required: true })
    onCloseRequested: ((refresh: boolean) => void)|undefined
    @Ref('editSermonInfoPanel')
    editSermonInfoPanel!: EditSermonInfoPanel
    isLoading: boolean = true
    initialEditInfo: SermonEditInfo|null = null
    formErrorMessage: string|null = null
    isSaving: boolean = false

    async created() {
        try {
            let sermonData = this.sermon?.data
            if (!sermonData)
                sermonData = await db.getSermonData(this.sermonId)

            if (sermonData) {
                this.initialEditInfo = {
                    isSermonRefAutomatic: false,
                    sermonRef: sermonData.sermonRef,
                    title: sermonData.title,
                    bibleRefs: sermonData.bibleRefs,
                    tagIds: sermonData.tagIds,
                    locations: sermonData.locations,
                    notes: sermonData.notes
                }
                this.isLoading = false
            }
        } catch (error) {
            console.error(`Error Loading Sermon: ${error}`)
            appManager.alertManager.publishError('Error loading sermon. Please try again later.')
        }
    }

    async onSavePressed() {
        const userId = appManager.userManager.state?.user?.id
        const info = this.editSermonInfoPanel.validateAndGetInfo()
        this.formErrorMessage = !info ? 'Please correct the error above' : null
        if (!userId || !info || this.formErrorMessage)
            return

        console.info(`title = ${info?.title}`)
        console.info('refs = ' + info?.bibleRefs?.map(r => r.start+'-'+r.end)?.join())
        console.info(`tags: ${info?.tagIds?.join()}`)
        console.info(`locations: ${info?.locations?.map(l => l.dateText+':'+l.locationId)?.join()}`)
        console.info(`notes: ${info?.notes?.text}`)

        this.isSaving = true
        try {
            await api.editSermon({
                sermonId: this.sermonId,
                newSermonRef: info.sermonRef,
                newTitle: info.title,
                newBibleRefs: info.bibleRefs,
                newTagIds: info.tagIds,
                newLocations: info.locations,
                newNotes: info.notes
            })

            appManager.alertManager.publishSuccess('Sermon saved successfully')

            if (this.onCloseRequested)
                this.onCloseRequested(true)
        } catch (error) {
            console.error(`Error Saving Sermon: ${JSON.stringify(error)}`)
            this.formErrorMessage = extractResponseErrorMessage(error) || 'There was an error saving the sermon. Please try again later.'
        }
        this.isSaving = false
    }

    private onCancelPressed() {
        if (this.onCloseRequested)
            this.onCloseRequested(false)
    }
}
