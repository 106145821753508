

























































































































import * as _ from 'lodash'
import Vue from 'vue'
import Component from 'vue-class-component'
import Spinner from '@/components/Spinner.vue'
import * as db from '@/core/Database'
import * as api from '@/services/ApiService'
import appManager from '@/services/AppManager'
import { BibleRefRange, Location, Sermon, Tag } from '@/core/Types'
import { Prop } from 'vue-property-decorator'
import SermonOverviewPanel from '@/components/sermons/SermonOverviewPanel.vue'
import SermonSearchPanel from '@/components/sermons/SermonSearchPanel.vue'
import { SermonSearchInfo } from '@/model/ComponentTypes'
import { convertSermonDtoToDoc } from '@/core/ApiTypes'
import { convertBibleRefRangeToString } from '@/core/BibleTypes'
import { Subscription } from 'rxjs'
import { UserMetadataState } from '@/services/UserMetadataManager'
import SelectOneDriveItemDialog from '@/components/onedrive/SelectOneDriveItemDialog.vue'
import { DriveItem } from '@microsoft/microsoft-graph-types'
import { convertDateTextYYYYMMDDToMMDDYYYY } from '@/core/DateUtils'

enum SermonsMode {
    NONE,               // done loading or searching
    LOADING,            // loading from DB
    SEARCHING,          // searching from API
    DELETING            // deleting sermon from API
}

@Component({
    components: {
        Spinner,
        SermonSearchPanel,
        SermonOverviewPanel,
        SelectOneDriveItemDialog
    }
})
export default class SermonsPanel extends Vue {
    readonly queryLimit: number = 20

    @Prop({ required: true })
    onAddSermonRequested!: VoidFunction
    @Prop({ required: true})
    onEditSermonRequested!: (sermon: Sermon) => void

    // user metadata
    userId: string|null = null
    userMetadata: UserMetadataState|null = null
    userMetadataSnapshotCounter: number = 0 // simple way to force a refresh when new items arrive
    tags: Tag[] = []
    locations: Location[] = []
    userMetadataSubscription: Subscription|null = null

    // sermons mode
    sermonsMode: SermonsMode = SermonsMode.LOADING
    sermonsModeCounter: number = 0

    // search
    expandedPanelIndex: number|undefined = -1
    searchInfo: SermonSearchInfo|null = null

    // sermons loaded from database or search
    sermons: Sermon[] = []
    hasMoreSermons: boolean = false

    // open sermon folder dialog
    isOpenSermonFolderDialogVisible: boolean = false
    openSermonFolderDialogCounter: number = 0 // simple way to reset the dialog each time it is opened
    openSermonFolderInitialFolder: string|null = null

    // delete sermon dialog
    isDeleteSermonDialogVisible: boolean = false
    deleteSermonDialogCounter: number = 0
    deleteSermon: Sermon|null = null

    created() {
        this.userId = appManager.userManager.state?.user?.id || null
        this.userMetadataSubscription = appManager.userMetadataManager.stateStream.subscribe(
            state => this.onUserMetadataChanged(state)
        )
        this.loadOrSearchMoreSermons()
    }

    destroyed() {
        if (this.userMetadataSubscription) {
            this.userMetadataSubscription.unsubscribe()
            this.userMetadataSubscription = null
        }
    }

    private onUserMetadataChanged(state: UserMetadataState) {
        this.userMetadata = state
        this.tags = state.tags
        this.locations = state.locations
        this.userMetadataSnapshotCounter++
    }

    private isSearchingSermons(): boolean {
        return this.sermonsMode === SermonsMode.SEARCHING
    }

    private isLoadingOrSearchingSermons(): boolean {
        return this.isSearchingSermons() || this.sermonsMode === SermonsMode.LOADING
    }

    private isLoadingSearchingOrDeletingSermons(): boolean {
        return this.isLoadingOrSearchingSermons() || this.sermonsMode === SermonsMode.DELETING
    }

    private async loadOrSearchMoreSermons() {
        this.sermonsModeCounter++
        console.info(`searchInfo: ${JSON.stringify(this.searchInfo)}`)
        if (this.searchInfo)
            await this.searchMoreSermons()
        else
            await this.loadMoreSermons()
    }

    private async loadMoreSermons() {
        if (!this.userId)
            return

        this.sermonsMode = SermonsMode.SEARCHING

        try {
            // query one more than the limit per query so we can know if there are more
            const snapshot = await db.querySermons({
                userId: this.userId,
                orderBySermonRefThenCreatedTimestampDescending: true,
                limit: this.queryLimit + 1,
                startAfter: _.last(this.sermons)
            })
            const count = Math.min(snapshot.size, this.queryLimit)
            this.hasMoreSermons = (snapshot.size > this.queryLimit)
            for (let i = 0; i < count; i++) {
                const doc = snapshot.docs[i]
                if (doc.exists && doc.data) {
                    this.sermons.push({
                        id: doc.id,
                        data: doc.data
                    })
                }
            }
        } catch (error) {
            console.error(`Error Loading Sermons: ${error}`)
            appManager.alertManager.publishError('Error loading sermons. Please try again later.')
        }

        this.sermonsMode = SermonsMode.NONE
    }

    private async searchMoreSermons() {
        if (!this.userId)
            return

        this.sermonsMode = SermonsMode.LOADING

        try {
            // search one more than the limit per query so we can know if there are more
            const snapshot = await api.searchSermons({
                userId: this.userId,
                sermonRefStart: this.searchInfo?.sermonRefRange?.start,
                sermonRefEnd: this.searchInfo?.sermonRefRange?.end,
                title: this.searchInfo?.title,
                bibleRef: this.searchInfo?.bibleRef,
                tagId: _.first(this.searchInfo?.tagIds),
                locationId: _.first(this.searchInfo?.locationIds),
                dateTextStart: this.searchInfo?.dateTextStart,
                dateTextEnd: this.searchInfo?.dateTextEnd,
                limit: this.queryLimit + 1,
                startAfterSermonId: _.last(this.sermons)?.id
            })
            const count = Math.min(snapshot.sermons.length, this.queryLimit)
            this.hasMoreSermons = (snapshot.sermons.length > this.queryLimit)
            for (let i = 0; i < count; i++) {
                const sermon = convertSermonDtoToDoc(snapshot.sermons[i])
                this.sermons.push(sermon)
            }
        } catch (error) {
            console.error(`Error Searching Sermons: ${error}`)
            appManager.alertManager.publishError('Error searching sermons. Please try again later.')
        }

        this.sermonsMode = SermonsMode.NONE
    }

    private onExpandedPanelIndexChanged(index: number|undefined) {
        this.expandedPanelIndex = index
        console.info(index)
    }

    private onSearchRequested(newSearchInfo: SermonSearchInfo|null) {
        this.searchInfo = newSearchInfo
        this.sermons = []
        this.loadOrSearchMoreSermons()
    }

    private getBibleRefText(bibleRef: BibleRefRange): string|null {
        return convertBibleRefRangeToString(bibleRef)
    }

    private getTagName(tagId: string): string {
        return this.tags.find(t => t.id === tagId)?.data?.name || ''
    }

    private getLocationName(locationId: string): string {
        return this.locations.find(l => l.id === locationId)?.data?.name || ''
    }

    private getSearchDateTextStart(): string|null {
        return convertDateTextYYYYMMDDToMMDDYYYY(this.searchInfo?.dateTextStart)
    }

    private getSearchDateTextEnd(): string|null {
        return convertDateTextYYYYMMDDToMMDDYYYY(this.searchInfo?.dateTextEnd)
    }

    private onDeleteSermonRequested(sermon: Sermon) {
        if (sermon) {
            this.deleteSermon = sermon
            this.isDeleteSermonDialogVisible = true
            this.deleteSermonDialogCounter++
        }
    }

    private async onDeleteSermonConfirmed() {
        if (!this.deleteSermon)
            return

        this.sermonsMode = SermonsMode.DELETING
        this.sermons = []

        try {
            await api.deleteSermon(this.deleteSermon.id)
        } catch (error) {
            console.error(`Error Deleting Sermon: ${error}`)
            appManager.alertManager.publishError('Error deleting sermon. Please try again later.')
        }

        this.sermonsMode = SermonsMode.NONE
        this.deleteSermon = null
        this.isDeleteSermonDialogVisible = false
        await this.loadOrSearchMoreSermons()
    }

    private onOpenSermonFolderRequested(sermon: Sermon) {
        let folderPath = this.userMetadata?.auth?.user?.data?.oneDrive?.sermonsRootFolderPath
        if (folderPath) {
            if (!folderPath.endsWith('/'))
                folderPath += '/'
            folderPath += this.getSermonRelativePath(sermon.data.sermonRef)
            this.openSermonFolderInitialFolder = folderPath
            this.isOpenSermonFolderDialogVisible = true
            this.openSermonFolderDialogCounter++
        }
    }

    private getSermonRelativePath(sermonRef: number): string {
        const topLevelFolder = this.getSermonTopLevelFolder(sermonRef)
        const subFolder = 'S' + sermonRef.toString().padStart(4, '0')

        return `${topLevelFolder}/${subFolder}`
    }

    /**
     * Top-level folders are grouped by 100's, but they do not have uniform naming.
     *   S0    -> stores 1-99
     *   S01   -> stores 100-199
     *   S02   -> stores 200-299
     *   S03   -> stores 300-299
     *   S1000 -> stores 1000-1099
     *   S1100 -> stores 1100-1199
     */
    private getSermonTopLevelFolder(sermonRef: number): string {
        if (sermonRef < 100)
            return 'S0'
        if (sermonRef < 1000) {
            const index = Math.floor(sermonRef / 100)
            return 'S' + index.toString().padStart(2, '0')
        }

        return 'S' + (sermonRef - (sermonRef % 100)).toString()
    }

    private onCloseSermonFolderDialogRequested(_selectedFolderPath: string|null, selectedFile: DriveItem|null) {
        this.isOpenSermonFolderDialogVisible = false
        if (selectedFile?.webUrl)
            window.open(selectedFile?.webUrl)
    }
}
