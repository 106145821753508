export enum HttpStatus {
    OK = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    CONFLICT = 409,
    INTERNAL_SERVER_ERROR = 500
}

export interface HttpError extends Error {
    status: number
}

function newHttpError(status: number, message: string): HttpError {
    return {
        name: 'HttpError',
        status: status,
        message: message
    }
}

export class HttpErrors extends Error {
    static readonly UNAUTHORIZED: HttpError = newHttpError(HttpStatus.UNAUTHORIZED, 'Unauthorized')

    static fromErrorOrBadRequest(error: any): HttpError {
        return HttpErrors.fromError(error, HttpStatus.BAD_REQUEST, 'Bad Request')
    }

    static fromErrorOrInternalServerError(error: any): HttpError {
        return HttpErrors.fromError(error, HttpStatus.INTERNAL_SERVER_ERROR, 'Internal Server Error')
    }

    static fromError(error: any, defaultStatus: number, defaultMessage: string): HttpError {
        const status = error.status || defaultStatus
        const message = error.message || defaultMessage
        return newHttpError(status, message)
    }

    static badRequest(message: string): HttpError {
        return newHttpError(HttpStatus.BAD_REQUEST, message)
    }

    static forbidden(message: string): HttpError {
        return newHttpError(HttpStatus.FORBIDDEN, message)
    }

    static notFound(message: string): HttpError {
        return newHttpError(HttpStatus.NOT_FOUND, message)
    }

    static conflict(message: string): HttpError {
        return newHttpError(HttpStatus.CONFLICT, message)
    }
}