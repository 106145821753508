



































import Vue from 'vue'
import Component from 'vue-class-component'
import FormTextField from '@/components/FormTextField.vue'
import { Prop } from 'vue-property-decorator'
import { isBlank } from '@/core/Utils'
import * as api from '@/services/ApiService'
import appManager from '@/services/AppManager'
import { extractResponseErrorMessage } from '@/services/ApiService'

@Component({
    components: {
        FormTextField
    }
})
export default class EditTagDialog extends Vue {
    @Prop()
    value: boolean|undefined // true to show the dialog
    @Prop({ required: true })
    onCloseRequested: (() => void)|undefined
    @Prop({ required: true })
    tagId: string|undefined
    @Prop({ required: true })
    name: string|undefined
    newName: string|null = null
    newNameErrorMessage: string|null = null
    formErrorMessage: string|null = null
    isSaving: boolean = false

    created() {
        this.newName = this.name || null
    }

    private onCancelPressed() {
        if (this.onCloseRequested)
            this.onCloseRequested()
    }

    private async onSavePressed(): Promise<void> {
        this.validatePage()
        if (this.formErrorMessage || !this.tagId)
            return

        this.isSaving = true
        try {
            await api.editTag({
                tagId: this.tagId,
                newName: this.newName || undefined,
            })

            // tag was changed, so close this dialog
            if (this.onCloseRequested)
                this.onCloseRequested()

            appManager.alertManager.publishSuccess('Tag was updated.')
        } catch (error) {
            console.error(`Error Saving Tag: ${JSON.stringify(error)}`)
            this.formErrorMessage = extractResponseErrorMessage(error) || 'There was an error updating the tag. Please try again later.'
        }
        this.isSaving = false
    }

    private validatePage() {
        this.newNameErrorMessage = isBlank(this.newName) ? 'Specify a name' : null

        const hasError = !!this.newNameErrorMessage
        this.formErrorMessage = hasError ? 'Please correct the error above' : null
    }
}
