/**
 * Returns true if the specified text contains any whitespace
 * @param text String to test
 */
export function containsSpace(text: string): boolean {
    return /\s/.test(text)
}

/**
 * Returns true if the text only contains alphabet and/or numeric characters
 * @param text String to test
 */
export function isAlphaNumeric(text: string): boolean {
    return text.match(/^[a-z0-9]+$/i) !== null
}

/**
 * Converts the string to a single integer number, trimming whitespace if necessary.
 * If the string is not a value integer, null is returned.
 */
export function convertStringToInt(text: string|undefined|null): number|null {
    if (!text)
        return null

    const value = +(text.trim())
    return (Number.isInteger(value) && !Number.isNaN(value)) ? value : null
}