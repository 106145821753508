import { BibleRefRange } from '@/core/Types'

export interface Book {
    abbr: string
    name: string
    chapters: Chapter[]
}

export interface Chapter {
    chapter: number
    verses: number
}

export interface BibleRefData {
    book: number        // 1-based index
    chapter: number     // 1-based index
    verse: number       // 1-based index
}

export const BIBLE: Book[] = require('@/core/Bible.json')
export const BIBLE_BOOK_NAMES: string[] = BIBLE.map(b => b.name)

/**
 * To make verse range searching and sorting faster, create a single large number to represent
 * verses of format: (BOOK * 1,000,000) + (CHAPTER * 1,000) + VERSE. This assumes all chapters and
 * verses are <= 999. Examples:
 *   Gen 1:1   -  1,001,001
 *   Rev 22:21 - 66,022,021
 *   Special Values - 66,022,000 - 66,022,999 represents the entire 22 chapter
 */
const BIBLE_REF_BOOK_FACTOR = 1_000_000
const BIBLE_REF_CHAPTER_FACTOR = 1_000
export const BIBLE_REF_ALL_START = 0                // used to signify all chapters or verses
export const BIBLE_REF_ALL_END = 999                // larger than largest chapter and verse counts

/**
 * Returns true if the value is a value Bible reference number
 */
export function isValidBibleRef(ref: number): boolean {
    return !!ref && ref >= BIBLE_REF_BOOK_FACTOR
}

/**
 * Returns true if the Bible book number is valid (1-66). This is basically index+1.
 */
export function isValidBibleBookNumber(bookNumber: number|undefined): boolean {
    return !!bookNumber && bookNumber >= 1 && bookNumber <= BIBLE.length
}

/**
 * Extracts the BibleRefData from the reference number if it is valid or returns null
 */
export function getBibleRefData(ref: number): BibleRefData|null {
    if (!isValidBibleRef(ref))
        return null

    const book = Math.floor(ref / BIBLE_REF_BOOK_FACTOR)
    const chapter = Math.floor((ref % BIBLE_REF_BOOK_FACTOR) / BIBLE_REF_CHAPTER_FACTOR)
    const verse = ref % BIBLE_REF_CHAPTER_FACTOR

    return {
        book,
        chapter,
        verse
    }
}

/**
 * Creates the bible reference number from the individual components
 * Example:
 *   book = 2
 *   chapter = 4
 *   verse = 10
 *   Result = 2_004_010
 */
export function createBibleRef(book: number, chapter: number, verse: number): number {
    return book * BIBLE_REF_BOOK_FACTOR +
        chapter * BIBLE_REF_CHAPTER_FACTOR +
        verse
}

/**
 * Converts the Bible ref range to text
 */
export function convertBibleRefRangeToString(range: BibleRefRange): string|null {
    const start = getBibleRefData(range.start)
    const end = getBibleRefData(range.end)
    if (!start || !end)
        return null

    const bookName = BIBLE_BOOK_NAMES[start.book - 1]
    if (start.chapter === BIBLE_REF_ALL_START)
        return bookName

    let text = `${bookName} ${start.chapter}`
    if (start.chapter !== end.chapter) {
        text += `-${end.chapter}`
    } else if (start.verse !== BIBLE_REF_ALL_START) {
        text += `:${start.verse}`
        if (start.verse !== end.verse)
            text += `-${end.verse}`
    }
    return text
}