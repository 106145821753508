import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import MyProfileView from '@/views/portal/MyProfileView.vue'
import LocationsView from '@/views/portal/LocationsView.vue'
import SermonsView from '@/views/portal/SermonsView.vue'
import TagsView from '@/views/portal/TagsView.vue'
import HomeView from '@/views/portal/HomeView.vue'
import OneDriveView from '@/views/portal/OneDriveView.vue'
import * as api from '@/services/ApiService'

Vue.use(VueRouter)

export enum RoutePaths {
  PORTAL_HOME = '/',
  LOCATIONS = '/locations',
  MY_PROFILE = '/my_profile',
  ONEDRIVE = '/onedrive',
  SERMONS = '/sermons',
  TAGS = '/tags',
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/locations',
    name: 'Locations',
    component: LocationsView
  },
  {
    path: '/my_profile',
    name: 'MyProfile',
    component: MyProfileView
  },
  {
    path: '/onedrive',
    name: 'OneDrive',
    component: OneDriveView
  },
  {
    path: '/sermons',
    name: 'Sermons',
    component: SermonsView
  },
  {
    path: '/tags',
    name: 'Tags',
    component: TagsView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// to keep a Google Cloud Run instance alive while the site is in use, ping it on each page transition
router.afterEach(() => {
  console.info('Pinging Server')
  api.ping().then().catch()
})

export default router
