import { DocumentData, FieldValue, Timestamp } from "./FirebaseTypes";

export enum UserRole {
    USER = 'USER',
    ADMIN = 'ADMIN'
}

export interface TimestampData extends DocumentData {
    createdTimestamp: Timestamp|FieldValue
    [field: string]: any
}

export interface IsActiveData extends TimestampData {
    isActive: boolean
}

export interface TextDocumentData {
    text?: string
}

export interface User {
    id: string
    data: UserData
}

export interface UserData extends IsActiveData {
    firstName: string
    firstNameLower: string
    lastName: string
    lastNameLower: string
    name: string
    nameLower: string
    email: string
    role: UserRole
    oneDrive?: UserOneDriveData
}

export interface UserOneDriveData {
    scope: string                       // required when refreshing token
    redirectUri: string                 // required when refreshing token
    accessToken?: string
    refreshToken?: string
    expiresAtTimestamp?: Timestamp      // expiration time of the accessToken
    sermonsRootFolderPath?: string
}

export interface Tag {
    id: string
    data: TagData
}

export interface TagData extends TimestampData {
    userId: string
    name: string
    sermonCount: number
}

export interface Location {
    id: string
    data: LocationData
}

export interface LocationData extends TimestampData {
    userId: string
    name: string
    nameLower: string
    sermonCount: number
}

export interface BibleRefRange {
    start: number                           // inclusive (0-999 means entire chapter)
    end: number                             // inclusive
}

export interface SermonLocationData {
    dateText: string                        // YYYY-MM-DD
    locationId: string
}

export interface Sermon {
    id: string
    data: SermonData
}

export interface SermonData extends TimestampData {
    userId: string
    sermonRef: number                       // numerical counter for sermons
    title: string
    bibleRefs?: BibleRefRange[]
    tagIds?: string[]
    locations?: SermonLocationData[]
    earliestLocationDateText?: string       // YYYY-MM-DD - earliest by date, not array order
    latestLocationDateText?: string         // YYYY-MM-DD - latest by date, not array order
    notes?: TextDocumentData
}