import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase'
import vuetify from './plugins/vuetify'
import * as db from '@/core/Database'
import { AlertManager } from '@/services/AlertManager'
import { UserManager } from "@/services/UserManager"
import { Log } from '@/services/Log'
import appManager from '@/services/AppManager'
import { BIBLE } from '@/core/BibleTypes'
import { UserMetadataManager } from '@/services/UserMetadataManager'
import { MicrosoftGraphService } from '@/services/MicrosoftGraphService'

const log = new Log('main')

log.info(`Books: ${BIBLE.length}`)
for (let i = 0; i < BIBLE.length; i++) {
  const book = BIBLE[i]
  for (let j = 0; j < book.chapters.length; j++) {
    const chapter = book.chapters[j]
    if (!chapter.verses || chapter.verses < 1 || chapter.verses > 176)
      log.warn(`Invalid Verse Count: ${book.name}  ${chapter.chapter}`)
    if (j > 0 && chapter.chapter <= book.chapters[j-1].chapter)
      log.warn(`Invalid Verse Count: ${book.name}  ${chapter.chapter}`)
  }
}

log.info('Initializing Firebase')

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCKJn9s6e8WqRT9bQQeRmFg04aJJAzeKFk",
  authDomain: "sermon-notes-p58.firebaseapp.com",
  databaseURL: "https://sermon-notes-p58.firebaseio.com",
  projectId: "sermon-notes-p58",
  storageBucket: "sermon-notes-p58.appspot.com",
  messagingSenderId: "941808208674",
  appId: "1:941808208674:web:46f6c4ca0f66f5073ff37b",
  measurementId: "G-NL3NBC7SF2"
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()

db.initializeDatabase(firebase.firestore())

log.info('Initializing App')
const userManager = new UserManager()
appManager.initialize(
    userManager,
    new UserMetadataManager(userManager),
    new MicrosoftGraphService(userManager),
    new AlertManager()
)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
