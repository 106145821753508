import axios, { AxiosError, AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import {
    CreateLocationRequest,
    CreateLocationResponse,
    CreateSermonRequest,
    CreateSermonResponse,
    CreateTagRequest,
    CreateTagResponse,
    CreateUserRequest,
    CreateUserResponse,
    EditLocationRequest,
    EditSermonRequest,
    EditTagRequest,
    EditUserRequest,
    OneDriveAuthResponse,
    OneDriveProcessAuthCodeRequest,
    OneDriveRefreshTokensRequest,
    SearchSermonsRequest,
    SearchSermonsResponse
} from '@/core/ApiTypes'
import appManager from '@/services/AppManager'

const baseUrl = '/api/v1'

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay})

export async function ping(): Promise<void> {
    await axios.get(`${baseUrl}/ping`)
}

export async function createUser(request: CreateUserRequest): Promise<CreateUserResponse> {
    const response = await postWithAuth(`${baseUrl}/users/create`, request)
    return response.data
}

export async function editUser(request: EditUserRequest): Promise<void> {
    await postWithAuth(`${baseUrl}/users/edit`, request)
}

export async function createTag(request: CreateTagRequest): Promise<CreateTagResponse> {
    const response = await postWithAuth(`${baseUrl}/tags/create`, request)
    return response.data
}

export async function editTag(request: EditTagRequest): Promise<void> {
    await postWithAuth(`${baseUrl}/tags/edit`, request)
}

export async function createLocation(request: CreateLocationRequest): Promise<CreateLocationResponse> {
    const response = await postWithAuth(`${baseUrl}/locations/create`, request)
    return response.data
}

export async function editLocation(request: EditLocationRequest): Promise<void> {
    await postWithAuth(`${baseUrl}/locations/edit`, request)
}

export async function createSermon(request: CreateSermonRequest): Promise<CreateSermonResponse> {
    const response = await postWithAuth(`${baseUrl}/sermons/create`, request)
    return response.data
}

export async function editSermon(request: EditSermonRequest): Promise<void> {
    await postWithAuth(`${baseUrl}/sermons/edit`, request)
}

export async function deleteSermon(sermonId: string): Promise<void> {
    await postWithAuth(`${baseUrl}/sermons/delete/${sermonId}`)
}

export async function searchSermons(request: SearchSermonsRequest): Promise<SearchSermonsResponse> {
    const response = await postWithAuth(`${baseUrl}/sermons/search`, request)
    return response.data
}

export async function oneDriveProcessAuthCode(request: OneDriveProcessAuthCodeRequest): Promise<OneDriveAuthResponse> {
    const response = await postWithAuth(`${baseUrl}/onedrive/authcode`, request)
    return response.data
}

export async function oneDriveRefreshTokens(request: OneDriveRefreshTokensRequest): Promise<OneDriveAuthResponse> {
    const response = await postWithAuth(`${baseUrl}/onedrive/refreshtokens`, request)
    return response.data
}

export function extractResponseErrorMessage(error: AxiosError): string|undefined {
    return error.response?.data?.message
}

async function getWithAuth(url: string): Promise<AxiosResponse> {
    const token = await appManager.userManager.createUserToken()
    return await axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

async function postWithAuth(url: string, data?: any): Promise<AxiosResponse> {
    const token = await appManager.userManager.createUserToken()
    return await axios.post(url, data, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}