export class Log {
    constructor(readonly source: string) {
    }

    debug(text: string) {
        console.info(`DEBUG ${this.source} - ${text}`)
    }

    info(text: string) {
        console.info(`INFO  ${this.source} - ${text}`)
    }

    warn(text: string) {
        console.warn(`WARN  ${this.source} - ${text}`)
    }
}