














import Vue from 'vue'
import Component from 'vue-class-component'
import LoginPanel from '@/components/entrance/LoginPanel.vue'
import appManager from '@/services/AppManager'

@Component({
    components: {
        LoginPanel
    }
})
export default class EntranceView extends Vue {
    getAppVersion(): string {
        return appManager.appVersion
    }
}
