





































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import FormTextField from '@/components/FormTextField.vue'
import * as api from '@/services/ApiService'
import appManager from '@/services/AppManager'
import { isBlank, isValidEmail } from '@/core/Utils'
import { extractResponseErrorMessage } from '@/services/ApiService'
import { UserRole } from '@/core/Types'
import { getUserRoleDescription, getUserRoleTitle } from '@/model/Helpers'

/**
 * Edits the profile of any user.
 *
 * If the user being edited is the current/active user:
 *   - role is not editable
 *   - an alert is shown such that if the email changes the user will need to log out/in
 *
 * If the user being edited is not the current/active user:
 *   - role is editable
 */
@Component({
    components: {
        FormTextField
    }
})
export default class EditUserProfileDialog extends Vue {
    @Prop()
    value: boolean|undefined // true to show the dialog
    @Prop({ required: true })
    onCloseRequested: (() => void)|undefined
    @Prop({ required: true })
    userId: string|undefined
    @Prop({ required: true })
    firstName: string|undefined
    @Prop({ required: true })
    lastName: string|undefined
    @Prop({ required: true })
    email: string|undefined
    @Prop()
    role: UserRole|undefined
    roles: UserRole[] = []
    newFirstName: string|null = null
    newFirstNameErrorMessage: string|null = null
    newLastName: string|null = null
    newLastNameErrorMessage: string|null = null
    newEmail: string|null = null
    newEmailErrorMessage: string|null = null
    newRole: UserRole|null = null
    newRoleErrorMessage: string|null = null
    formErrorMessage: string|null = null
    isSaving: boolean = false
    isEditingActiveUser: boolean = false

    // noinspection JSUnusedGlobalSymbols
    created() {
        const user = appManager.userManager.state?.user
        if (user) {
            this.roles = []
            if (user.data.role === UserRole.ADMIN)
                this.roles.push(UserRole.ADMIN)
            this.roles.push(UserRole.USER)
        }

        this.newFirstName = this.firstName || null
        this.newLastName = this.lastName || null
        this.newEmail = this.email || null
        this.newRole = this.role || null
        this.isEditingActiveUser = user?.id === this.userId
    }

    getUserRoleTitleLocal(role: UserRole): string {
        return getUserRoleTitle(role)
    }

    getUserRoleDescriptionLocal(role: UserRole): string {
        return getUserRoleDescription(role)
    }

    private onCancelPressed() {
        if (this.onCloseRequested)
            this.onCloseRequested()
    }

    private async onSavePressed(): Promise<void> {
        this.validatePage()
        if (this.formErrorMessage || !this.userId)
            return

        const newEmailLower = this.newEmail?.trim()?.toLocaleLowerCase()
        const isEmailChanged = newEmailLower !== this.email

        this.isSaving = true
        try {
            await api.editUser({
                userId: this.userId,
                newFirstName: this.newFirstName || undefined,
                newLastName: this.newLastName || undefined,
                newEmail: isEmailChanged ? newEmailLower : undefined,
                newRole: (!this.newRole || this.role === this.newRole) ? undefined : this.newRole
            })

            // profile was changed, so close this dialog
            if (this.onCloseRequested)
                this.onCloseRequested()

            if (this.isEditingActiveUser && isEmailChanged) {
                // if the email changed, force the user to log back in as Firebase expires the token
                appManager.alertManager.publishSuccess('Your profile was updated. Please log in with your updated email.')
                await appManager.userManager.signOut()
            } else {
                appManager.alertManager.publishSuccess('User profile was updated.')
            }
        } catch (error) {
            console.error(`Error Saving Profile: ${JSON.stringify(error)}`)
            this.formErrorMessage = extractResponseErrorMessage(error) || 'There was an error updating your profile. Please try again later.'
        }
        this.isSaving = false
    }

    validatePage() {
        this.newFirstNameErrorMessage = isBlank(this.newFirstName) ? 'Specify a first name' : null
        this.newLastNameErrorMessage = isBlank(this.newLastName) ? 'Specify a last name' : null
        this.newEmailErrorMessage = !isValidEmail(this.newEmail) ? 'Specify a valid email address' : null

        const hasError = !!this.newFirstNameErrorMessage ||
            !!this.newLastNameErrorMessage ||
            !!this.newEmailErrorMessage
        this.formErrorMessage = hasError ? 'Please correct the error above' : null
    }
}
