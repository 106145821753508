import { BibleRefRange, SermonLocationData, TextDocumentData } from '@/core/Types'
import { convertStringToInt } from '@/core/StringUtils'
import {
    BIBLE_BOOK_NAMES,
    BIBLE_REF_ALL_END,
    BIBLE_REF_ALL_START,
    createBibleRef,
    getBibleRefData,
    isValidBibleBookNumber
} from '@/core/BibleTypes'
import { isBlank } from '@/core/Utils'

export interface SermonEditInfo {
    isSermonRefAutomatic: boolean
    sermonRef: number                       // numerical counter for sermons
    title: string
    bibleRefs?: BibleRefRange[]
    tagIds?: string[]
    locations?: SermonLocationData[]
    notes?: TextDocumentData
}

export interface SermonSearchInfo {
    sermonRefRange?: NumberRange
    title?: string
    bibleRef?: BibleRefRange
    tagIds?: string[]
    locationIds?: string[]
    dateTextStart?: string                  // YYYY-MM-DD
    dateTextEnd?: string                    // YYYY-MM-DD
}

export class TextValuePair<T> {
    constructor(public readonly text: string|undefined,
                public readonly value: T|null) {
    }
}

export class NumberRange {
    constructor(public readonly start: number,
                public readonly end: number) {
    }

    toString(): string {
        return (this.start === this.end) ? this.start?.toString() : `${this.start}-${this.end}`
    }

    static newPairFromValue(range: NumberRange|null|undefined): TextValuePair<NumberRange> {
        const text = range?.toString() || ''
        return new TextValuePair(text, range || null)
    }

    static newPairFromText(text: string|undefined): TextValuePair<NumberRange> {
        const range = NumberRange.fromText(text)
        return new TextValuePair(text, range)
    }

    static fromText(rangeText: string|undefined|null): NumberRange|null {
        if (!rangeText)
            return null

        // if there is no '-' range separator it is a single value
        const index = rangeText.indexOf('-')
        if (index < 0) {
            const value = convertStringToInt(rangeText)
            return (value !== null) ? new NumberRange(value, value) : null
        }

        const startText = rangeText.substring(0, index)
        const endText = rangeText.substring(index+1)
        const start = convertStringToInt(startText)
        const end = convertStringToInt(endText)

        return (start != null && end != null) ? new NumberRange(start, end) : null
    }
}

export class BibleRefRangeEditInfo {
    constructor(public readonly book: number,
                public readonly chapterRange: TextValuePair<NumberRange>|undefined,
                public readonly verseRange: TextValuePair<NumberRange>|undefined) {
    }

    get isBlank(): boolean {
        return !this.book &&
            isBlank(this.chapterRange?.text) &&
            isBlank(this.verseRange?.text)
    }

    get isValid(): boolean {
        return this.isValidBook && this.isValidChapter && this.isValidVerseRange
    }

    get isValidBook(): boolean {
        return isValidBibleBookNumber(this.book)
    }

    get isValidChapter(): boolean {
        return this.isRangeValid(this.chapterRange)
    }

    get isValidVerseRange(): boolean {
        return this.isRangeValid(this.verseRange)
    }

    get bookName(): string|null {
        return this.isValidBook ? BIBLE_BOOK_NAMES[this.book-1] : null
    }

    toBibleRefRange(): BibleRefRange|null {
        if (!this.isValid)
            return null

        const [chapterStart, chapterEnd] = BibleRefRangeEditInfo.getRangeValuesOrAll(this.chapterRange)
        const [verseStart, verseEnd] = BibleRefRangeEditInfo.getRangeValuesOrAll(this.verseRange)

        return {
            start: createBibleRef(this.book, chapterStart, verseStart),
            end: createBibleRef(this.book, chapterEnd, verseEnd)
        }
    }

    copyWithBook(book: number): BibleRefRangeEditInfo {
        return new BibleRefRangeEditInfo(
            book,
            this.chapterRange,
            this.verseRange,
        )
    }

    copyWithChapterRange(chapterRange: TextValuePair<NumberRange>|undefined): BibleRefRangeEditInfo {
        return new BibleRefRangeEditInfo(
            this.book,
            chapterRange,
            this.verseRange,
        )
    }

    copyWithVerseRange(verseRange: TextValuePair<NumberRange>|undefined): BibleRefRangeEditInfo {
        return new BibleRefRangeEditInfo(
            this.book,
            this.chapterRange,
            verseRange,
        )
    }

    toString(): string {
        const chapterRangeText = `${this.chapterRange?.value?.start}-${this.chapterRange?.value?.end}`
        const verseRangeText = `${this.verseRange?.value?.start}-${this.verseRange?.value?.end}`
        return `${this.book}_${chapterRangeText}_${this.chapterRange?.text}_${verseRangeText}_${this.verseRange?.text}`
    }

    static empty(): BibleRefRangeEditInfo {
        return new BibleRefRangeEditInfo(0, undefined, undefined)
    }

    static fromStartEnd(start: number, end: number): BibleRefRangeEditInfo {
        const startRef = getBibleRefData(start)
        const endRef = getBibleRefData(end)
        if (!startRef || !endRef)
            return BibleRefRangeEditInfo.empty()

        return new BibleRefRangeEditInfo(
            startRef.book,
            BibleRefRangeEditInfo.createTextRangePair(startRef.chapter, endRef.chapter),
            BibleRefRangeEditInfo.createTextRangePair(startRef.verse, endRef.verse),
        )
    }

    private isRangeValid(range: TextValuePair<NumberRange>|undefined): boolean {
        // will be valid if the text is blank (meaning all) or there is a valid range of numbers
        if (isBlank(range?.text))
            return true
        if (!range?.value)
            return false

        return Number.isInteger(range.value.start) &&
            Number.isInteger(range.value.end) &&
            range.value.start <= range.value.end &&
            range.value.start >= BIBLE_REF_ALL_START &&
            range.value.end <= BIBLE_REF_ALL_END
    }

    private static getRangeValuesOrAll(range: TextValuePair<NumberRange>|undefined): [number, number] {
        const start = range?.value?.start || BIBLE_REF_ALL_START
        const end = range?.value?.end || BIBLE_REF_ALL_END
        return [start, end]
    }

    private static createTextRangePair(start: number, end: number): TextValuePair<NumberRange> {
        const range = new NumberRange(start, end)
        if (start === BIBLE_REF_ALL_START && end === BIBLE_REF_ALL_END)
            return new TextValuePair<NumberRange>('', range)

        return NumberRange.newPairFromValue(range)
    }
}