import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { AppColors } from '@/model/AppColors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: AppColors.primary,
                secondary: AppColors.secondary,
                accent: AppColors.accent,
                error: AppColors.error,
                warning: AppColors.warning,
                info: AppColors.info,
                success: AppColors.success
            },
        },
    },
})
