
















import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { BaseVueComponent } from '@/components/BaseVueComponent'
import { NumberRange, TextValuePair } from '@/model/ComponentTypes'

@Component
export default class FormNumberRangeTextField extends BaseVueComponent {
    @Prop()
    value: TextValuePair<NumberRange>|undefined
    @Prop({ type: String })
    label: string|undefined
    @Prop({ type: Boolean, default: false })
    embedLabel: boolean|undefined
    @Prop({ type: Boolean, default: false })
    disableAutoComplete: boolean|undefined
    @Prop({ type: Boolean, default: false })
    hideDetails: boolean|undefined
    @Prop({ type: String })
    errorMessage: string|undefined
    @Prop()
    onEnterPressed: (() => void)|undefined

    getText(): string|undefined {
        return this.value?.text
    }

    onTextChanged(text: string|undefined) {
        const newValue = NumberRange.newPairFromText(text)
        this.emitValueChanged(newValue)
    }

    onKeyUpEnter() {
        if (this.onEnterPressed)
            this.onEnterPressed()
    }
}
