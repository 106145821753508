/**
 * Returns true if this is a valid email address
 * https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 */
export function isValidEmail(email: string|undefined|null): boolean {
    if (!email)
        return false

    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/**
 * Returns an error message if this is an invalid password or null if it is valid
 */
export function validatePassword(password: string|undefined|null): string|null {
    if (isBlank(password) || password!.length < 8)
        return 'Password must be at least 8 characters'

    return null
}

/**
 * Returns true if the string is empty, null, or all whitespaces
 */
export function isBlank(text: string|undefined|null): boolean {
    return !text || text.trim().length === 0
}

/**
 * Returns true if the string is NOT empty, null, or all whitespaces
 */
export function isNotBlank(text: string|undefined|null): boolean {
    return !isBlank(text)
}

/**
 * Calls the handler function on each item in parallel
 */
export async function parallelForEach<T>(items: T[]|undefined, handler: (item: T) => Promise<void>): Promise<void> {
    if (items) {
        const promises = items.map(handler);

        await Promise.all(promises);
    }
}