

















































import Vue from 'vue'
import Component from 'vue-class-component'
import appManager from '@/services/AppManager'
import { AuthStateData } from '@/services/UserManager'
import { Subscription } from 'rxjs'
import Spinner from '@/components/Spinner.vue'
import ChangeCurrentUserPasswordDialog from '@/components/users/ChangeCurrentUserPasswordDialog.vue'
import EditUserProfileDialog from '@/components/users/EditUserProfileDialog.vue'

@Component({
    components: {
        Spinner,
        EditUserProfileDialog,
        ChangeCurrentUserPasswordDialog
    }
})
export default class MyProfileView extends Vue {
    // user state
    authState: AuthStateData|null = null
    authStateCounter: number = 0 // simple way to force a refresh when the state changes
    authStateSubscription: Subscription|undefined

    // edit profile dialog
    isEditProfileDialogVisible: boolean = false
    editProfileDialogCounter: number = 0 // simple way to reset the dialog before opening each time

    // change password dialog
    isChangePasswordDialogVisible: boolean = false
    changePasswordDialogCounter: number = 0 // simple way to reset the dialog before opening each time

    // noinspection JSUnusedGlobalSymbols
    created() {
        this.authStateSubscription = appManager.userManager.stateStream.subscribe(state => this.onAuthStateChanged(state))
    }

    // noinspection JSUnusedGlobalSymbols
    destroyed() {
        if (this.authStateSubscription)
            this.authStateSubscription.unsubscribe()
    }

    get userName(): string {
        return this.authState?.user?.data?.name || ''
    }

    get userEmail(): string {
        return this.authState?.user?.data?.email || ''
    }

    private onAuthStateChanged(state: AuthStateData) {
        this.authState = state
        this.authStateCounter += 1
    }

    private onEditPressed() {
        this.editProfileDialogCounter += 1
        this.isEditProfileDialogVisible = true
    }

    private onChangePasswordPressed() {
        this.changePasswordDialogCounter += 1
        this.isChangePasswordDialogVisible = true
    }

    private onCloseEditProfileDialogRequested() {
        this.isEditProfileDialogVisible = false
    }

    private onCloseChangePasswordDialogRequested() {
        this.isChangePasswordDialogVisible = false
    }
}
