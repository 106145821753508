
































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { BibleRefRange, Location, Sermon, Tag } from '@/core/Types'
import { Subscription } from 'rxjs'
import appManager from '@/services/AppManager'
import { UserMetadataState } from '@/services/UserMetadataManager'
import { convertBibleRefRangeToString } from '@/core/BibleTypes'
import { convertDateTextYYYYMMDDToMMDDYYYY } from '@/core/DateUtils'
import { isBlank } from '@/core/Utils'

@Component
export default class SermonOverviewPanel extends Vue {
    @Prop({ required: true })
    sermon!: Sermon
    @Prop({ required: true })
    onEditSermonRequested: VoidFunction|undefined
    @Prop({ required: true })
    onDeleteSermonRequested: VoidFunction|undefined
    @Prop({ required: true })
    onOpenSermonFolderRequested: VoidFunction|undefined

    // user metadata
    userMetadataState: UserMetadataState|null = null
    userMetadataSnapshotCounter: number = 0 // simple way to force a refresh when new items arrive
    tags: Tag[] = []
    locations: Location[] = []
    userMetadataSubscription: Subscription|null = null

    created() {
        this.userMetadataSubscription = appManager.userMetadataManager.stateStream.subscribe(
            state => this.onUserMetadataChanged(state)
        )
    }

    destroyed() {
        if (this.userMetadataSubscription) {
            this.userMetadataSubscription.unsubscribe()
            this.userMetadataSubscription = null
        }
    }

    private onUserMetadataChanged(state: UserMetadataState) {
        this.userMetadataState = state
        this.tags = state.tags
        this.locations = state.locations
        this.userMetadataSnapshotCounter++
    }

    private hasBibleRefs(): boolean {
        const bibleRefs = this.sermon?.data?.bibleRefs
        return !!bibleRefs && bibleRefs.length > 0
    }

    private hasTags(): boolean {
        const tagIds = this.sermon?.data?.tagIds
        return !!tagIds && tagIds.length > 0
    }

    private hasLocations(): boolean {
        const locations = this.sermon?.data?.locations
        return !!locations && locations.length > 0
    }

    private isOneDriveConfigured(): boolean {
        const oneDrive = this.userMetadataState?.auth?.user?.data?.oneDrive
        return !!oneDrive?.accessToken && !!oneDrive?.sermonsRootFolderPath
    }

    private getBibleRefText(bibleRef: BibleRefRange): string|null {
        return convertBibleRefRangeToString(bibleRef)
    }

    private getTagName(tagId: string): string {
        return this.tags.find(t => t.id === tagId)?.data?.name || ''
    }

    private getLocationName(locationId: string): string {
        return this.locations.find(l => l.id === locationId)?.data?.name || ''
    }

    private isEmptyDateText(dateText: string): boolean {
        return isBlank(dateText);
    }

    private getDateTextFormatted(dateText: string): string {
        return convertDateTextYYYYMMDDToMMDDYYYY(dateText) || ''
    }

    private hasNotes(): boolean {
        return !isBlank(this.sermon?.data?.notes?.text)
    }
}
