


























import Vue from 'vue'
import Component from 'vue-class-component'
import Spinner from '@/components/Spinner.vue'
import SermonsPanel from '@/components/sermons/SermonsPanel.vue'
import CreateSermonPanel from '@/components/sermons/CreateSermonPanel.vue'
import EditSermonPanel from '@/components/sermons/EditSermonPanel.vue'
import { Sermon } from '@/core/Types'

@Component({
    components: {
        Spinner,
        SermonsPanel,
        CreateSermonPanel,
        EditSermonPanel
    }
})
export default class SermonsView extends Vue {
    updateSermonListCounter: number = 0     // used to reset the SermonsPanel as necessary
    addSermonCounter: number = 0            // used to reset the CreateSermonPanel each time
    editSermonCounter: number = 0           // used to reset the EditSermonInfoPanel each time
    editSermon: Sermon|null = null

    private getWindowIndex(): number {
        return (this.isModeAdd() || this.isModeEdit()) ? 1 : 0
    }

    private isModeAdd(): boolean {
        return !!this.$route.query['add']
    }

    private isModeEdit(): boolean {
        return !!this.getEditSermonId()
    }

    private getEditSermonId(): string|(string|null)[] {
        return this.$route.query['edit']
    }

    private onAddSermonRequested() {
        this.addSermonCounter++
        this.$router.push({
            query: {
                add: '1'
            }
        })
    }

    private onEditSermonRequested(sermon: Sermon) {
        this.editSermon = sermon
        this.editSermonCounter++
        this.$router.push({
            query: {
                edit: sermon.id
            }
        })
    }

    private onCloseAddSermonRequested(refresh: boolean) {
        if (refresh)
            this.updateSermonListCounter++
        this.$router.push({})
    }
}
