import { AuthProvider, Client, GraphError } from '@microsoft/microsoft-graph-client'
import { DriveItem } from '@microsoft/microsoft-graph-types'
import { UserManager } from '@/services/UserManager'
import * as api from '@/services/ApiService'
import { HttpStatus } from '@/core/Http'

export class MicrosoftGraphService {
    private client: Client
    private accessToken: string|undefined
    private forceRefresh: boolean = false

    constructor(private readonly userManager: UserManager) {
        this.client = this.createClient()
    }

    /**
     * Get the children of the specified drive item or of the root if undefined
     */
    async getDriveItemChildren(driveItemId?: string): Promise<DriveItem[]|undefined> {
        const path = driveItemId ? `/me/drive/items/${driveItemId}` : '/me/drive/root/children'

        return await this.getPathValue(path)
    }

    /**
     * Get the children of the specified drive path or of the root if undefined.
     * @param drivePath Should be a Linux-style path (/path/to/folder)
     */
    async getDrivePathChildren(drivePath?: string): Promise<DriveItem[]|undefined> {
        const path = (drivePath && drivePath !== '/') ? `/me/drive/root:${drivePath}:/children` : '/me/drive/root/children'

        return await this.getPathValue(path)
    }

    private async getPathValue(path: string): Promise<any|undefined> {
        // loop twice to allow the access token to be refreshed if necessary
        for (let i = 0; i < 2; i++) {
            try {
                const response = await this.client.api(path).get()
                return response.value
            } catch (error) {
                console.error(`getPathValue: ${error}`)
                const graphError = error as GraphError
                if (i === 0 && graphError?.statusCode === HttpStatus.UNAUTHORIZED) {
                    this.resetGraphClient()
                } else {
                    throw error
                }
            }
        }

        // should never get here
        return undefined
    }

    private resetGraphClient() {
        this.forceRefresh = true
    }

    private createClient(): Client {
        const authProvider: AuthProvider = async (done) => {
            let token: string|undefined
            if (!this.forceRefresh)
                token = this.userManager.state?.user?.data?.oneDrive?.accessToken
            if (!token) {
                const userId = this.userManager.state?.user?.id
                if (userId) {
                    try {
                        const response = await api.oneDriveRefreshTokens({userId: userId})
                        token = response.accessToken
                    } catch (error) {
                        console.error(`Error Refreshing Token: ${error}`)
                    }
                }
            }

            this.accessToken = token
            this.forceRefresh = false

            // send the token back to the graph library or return an error
            if (token)
                done(null, token)
            else
                done(new Error('Could not load access token'), null)
        }
        return Client.init({
            authProvider: authProvider
        })
    }
}