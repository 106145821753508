




























import * as _ from 'lodash'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { BIBLE_BOOK_NAMES } from '@/core/BibleTypes'
import { BibleRefRangeEditInfo, NumberRange, TextValuePair } from '@/model/ComponentTypes'
import { BaseVueComponent } from '@/components/BaseVueComponent'
import FormNumberRangeTextField from '@/components/FormNumberRangeTextField.vue'

@Component({
    components: {
        FormNumberRangeTextField
    }
})
export default class BibleRefRangeComponent extends BaseVueComponent {
    @Prop({ required: true })
    value: BibleRefRangeEditInfo|undefined
    @Prop({ type: Boolean, default: true })
    hideDetails: boolean|undefined
    @Prop()
    onEnterPressed: (() => void)|undefined
    bibleBookNames = BIBLE_BOOK_NAMES

    private onSelectedBookNameChanged(bookName: string|undefined) {
        const bookIndex = _.indexOf(this.bibleBookNames, bookName)
        const newValue = this.value?.copyWithBook(bookIndex + 1)
        this.emitValueChanged(newValue)
    }

    private onChapterRangeChanged(range: TextValuePair<NumberRange>|undefined) {
        const newValue = this.value?.copyWithChapterRange(range)
        this.emitValueChanged(newValue)
    }

    private onVerseRangeChanged(range: TextValuePair<NumberRange>|undefined) {
        const newValue = this.value?.copyWithVerseRange(range)
        this.emitValueChanged(newValue)
    }
}
