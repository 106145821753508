
















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Location, Tag } from '@/core/Types'
import FormTextField from '@/components/FormTextField.vue'
import FormTextDocument from '@/components/FormTextDocument.vue'
import BibleRefRangeComponent from '@/components/BibleRefRangeComponent.vue'
import { BibleRefRangeEditInfo, NumberRange, SermonSearchInfo, TextValuePair } from '@/model/ComponentTypes'
import { UserMetadataState } from '@/services/UserMetadataManager'
import { Subscription } from 'rxjs'
import appManager from '@/services/AppManager'
import { isBlank } from '@/core/Utils'
import FormDateTextField from '@/components/FormDateTextField.vue'
import FormNumberRangeTextField from '@/components/FormNumberRangeTextField.vue'

@Component({
    components: {
        FormTextDocument,
        FormTextField,
        FormDateTextField,
        FormNumberRangeTextField,
        BibleRefRangeComponent,
    }
})
export default class SermonSearchPanel extends Vue {
    @Prop({ required: true })
    initialSearchInfo: SermonSearchInfo|undefined
    @Prop({ required: true })
    isSearching: boolean|undefined
    @Prop({ required: true })
    onSearchRequested: ((searchInfo: SermonSearchInfo|null) => void)|undefined
    sermonRefRange: TextValuePair<NumberRange>|null = null
    sermonRefRangeErrorMessage: string|null = null
    title: string|null = null
    bibleRefRange: BibleRefRangeEditInfo = BibleRefRangeEditInfo.empty()
    bibleRefRangeCounter: number = 0
    bibleRefRangeErrorMessage: string|null = null
    dateTextStart: string|null = null
    dateTextEnd: string|null = null
    clearCounter: number = 0 // simple way to reset components when clear is pressed

    // user metadata
    userMetadataSnapshotCounter: number = 0 // simple way to force a refresh when new items arrive
    userMetadataSubscription: Subscription|null = null

    // Tags - the final truth is 'selectedTagIds', with 'availableTags' containing all available tags for
    //        the user, and 'selectedTags' being a combined list from 'selectedTagIds' + 'availableTags'.
    selectedTagIds: string[] = []
    selectedTags: Tag[] = []
    availableTags: Tag[] = []

    // Locations (see notes above for Tags for data structures)
    selectedLocationIds: string[] = []
    selectedLocations: Location[] = []
    availableLocations: Location[] = []

    created() {
        this.userMetadataSubscription = appManager.userMetadataManager.stateStream.subscribe(s => this.onUserMetadataChanged(s))

        if (this.initialSearchInfo) {
            this.sermonRefRange = NumberRange.newPairFromValue(this.initialSearchInfo.sermonRefRange)
            this.title = this.initialSearchInfo.title || null
            const bibleRef = this.initialSearchInfo.bibleRef
            if (bibleRef)
                this.bibleRefRange = BibleRefRangeEditInfo.fromStartEnd(bibleRef.start, bibleRef.end)
            else
                this.bibleRefRange = BibleRefRangeEditInfo.empty()
            this.selectedTagIds = this.initialSearchInfo.tagIds || []
            this.selectedLocationIds = this.initialSearchInfo.locationIds || []
            this.dateTextStart = this.initialSearchInfo.dateTextStart || null
            this.dateTextEnd = this.initialSearchInfo.dateTextEnd || null
        }
    }

    destroyed() {
        if (this.userMetadataSubscription) {
            this.userMetadataSubscription.unsubscribe()
            this.userMetadataSubscription = null
        }
    }

    private isSearchEnabled() {
        return !isBlank(this.sermonRefRange?.text) ||
            !isBlank(this.title) ||
            !this.bibleRefRange.isBlank ||
            this.selectedTagIds.length > 0 ||
            this.selectedLocationIds.length > 0 ||
            !isBlank(this.dateTextStart) ||
            !isBlank(this.dateTextEnd)
    }

    private onUserMetadataChanged(state: UserMetadataState) {
        this.availableTags = state.tags
        this.selectedTags = this.selectedTagIds
            .map(tagId => state.tags.findIndex(t => t.id === tagId))
            .filter(index => index >= 0)
            .map(index => state.tags[index])

        this.availableLocations = state.locations
        this.selectedLocations = this.selectedLocationIds
            .map(locId => state.locations.findIndex(l => l.id === locId))
            .filter(index => index >= 0)
            .map(index => state.locations[index])
        this.userMetadataSnapshotCounter++
    }

    private onBibleRefChanged(newValue: BibleRefRangeEditInfo) {
        if (newValue) {
            console.info(`newBibleRef: ${JSON.stringify(newValue)}`)
            this.bibleRefRange = newValue
            this.bibleRefRangeCounter++
        }
    }

    private onSelectedTagsChanged(newSelectedTagId: string|undefined) {
        this.selectedTagIds = (newSelectedTagId) ? [newSelectedTagId] : []
        console.info(`newSelectedTagId: ${newSelectedTagId}`)
    }

    private onSelectedLocationsChanged(newSelectedLocationId: string|undefined) {
        this.selectedLocationIds = (newSelectedLocationId) ? [newSelectedLocationId] : []
        console.info(`newSelectedLocationId: ${newSelectedLocationId}`)
    }

    private onClearPressed() {
        this.sermonRefRange = null
        this.sermonRefRangeErrorMessage = null
        this.title = null
        this.bibleRefRange = BibleRefRangeEditInfo.empty()
        this.bibleRefRangeErrorMessage = null
        this.selectedTagIds = []
        this.selectedTags = []
        this.selectedLocationIds = []
        this.selectedLocations = []
        this.dateTextStart = null
        this.dateTextEnd = null
        this.clearCounter += 1

        if (this.onSearchRequested)
            this.onSearchRequested(null)
    }

    private onSearchPressed() {
        let sermonRefRangeValue: NumberRange|undefined|null
        if (isBlank(this.sermonRefRange?.text)) {
            this.sermonRefRangeErrorMessage = null
        } else {
            sermonRefRangeValue = this.sermonRefRange?.value
            this.sermonRefRangeErrorMessage = this.sermonRefRange?.value ? null : 'Invalid reference number'
        }

        this.bibleRefRangeErrorMessage = (this.bibleRefRange.isBlank || this.bibleRefRange.isValid) ? null : 'Invalid Bible reference number'

        if (this.sermonRefRangeErrorMessage || this.bibleRefRangeErrorMessage)
            return

        if (this.onSearchRequested) {
            const info: SermonSearchInfo = {
                sermonRefRange: sermonRefRangeValue || undefined,
                title: this.title?.trim(),
                bibleRef: this.bibleRefRange?.toBibleRefRange() || undefined,
                tagIds: this.selectedTagIds,
                locationIds: this.selectedLocationIds,
                dateTextStart: this.dateTextStart || undefined,
                dateTextEnd: this.dateTextEnd || undefined
            }
            this.onSearchRequested(info)
        }
    }
}
