



































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import * as api from '@/services/ApiService'
import FormTextField from '@/components/FormTextField.vue'
import appManager from '@/services/AppManager'
import { isBlank } from '@/core/Utils'
import { extractResponseErrorMessage } from '@/services/ApiService'

@Component({
    components: {
        FormTextField
    }
})
export default class CreateTagDialog extends Vue {
    @Prop()
    value: boolean|undefined // true to show the dialog
    @Prop()
    onCloseRequested: (() => void)|undefined
    name: string|null = null
    nameErrorMessage: string|null = null
    formErrorMessage: string|null = null
    isCreating: boolean = false

    onCancelPressed() {
        if (this.onCloseRequested)
            this.onCloseRequested()
    }

    async onCreatePressed(): Promise<void> {
        this.validatePage()
        if (this.formErrorMessage)
            return

        this.isCreating = true

        try {
            await api.createTag({
                name: this.name!
            })

            this.formErrorMessage = null

            if (this.onCloseRequested)
                this.onCloseRequested()

            appManager.alertManager.publishSuccess('Tag successfully added')
        } catch (error) {
            this.formErrorMessage = extractResponseErrorMessage(error) || error.message || 'Error creating tag. Please try again later.'
        }

        this.isCreating = false
    }

    validatePage() {
        this.nameErrorMessage = isBlank(this.name) ? 'Specify a name' : null

        const hasError = !!this.nameErrorMessage
        this.formErrorMessage = hasError ? 'Please correct the error above' : null
    }
}
